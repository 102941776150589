import { useEffect, useState } from 'react'

export function useAudioLevel(mediaStream?: MediaStream) {
  const [audioLevel, setAudioLevel] = useState(0)

  useEffect(() => {
    if (!mediaStream) return

    const audioTracks = mediaStream.getAudioTracks()

    if (!audioTracks?.[0]) return

    const audioContext = new AudioContext()
    const analyser = audioContext.createAnalyser()
    analyser.fftSize = 256 // Lower value makes it more responsive
    const bufferLength = analyser.frequencyBinCount
    const dataArray = new Uint8Array(bufferLength)

    const source = audioContext.createMediaStreamSource(mediaStream)
    source.connect(analyser)

    const updateAudioLevel = () => {
      analyser.getByteFrequencyData(dataArray)
      const avgLevel = dataArray.reduce((a, b) => a + b, 0) / bufferLength
      setAudioLevel(avgLevel)
      requestAnimationFrame(updateAudioLevel)
    }

    updateAudioLevel()

    return () => {
      audioContext.close()
    }
  }, [mediaStream])

  return audioLevel
}
