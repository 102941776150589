// eslint-disable-next-line react-refresh/only-export-components
import { lazy, Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import { SharedChildrenPath } from '@/constants/shared_pages.ts'
import { PathKind } from '@/helpers/path.ts'
import { hasRole } from '@/helpers/user.ts'
import useAppContext from '@/hooks/useAppProvider.ts'
import useAuthContext from '@/hooks/useAuthProvider.ts'
import SafeguardingSidebar from '@/pages/SafeguardingPage/components/SafeguardingSidebar.tsx'
import recordRoute from '@/pages/SharedPage/Record/route.tsx'
import TeacherSidebar from '@/pages/TeacherPage/TeacherSidebar.tsx'
import { sidebarHiddenPaths } from '@/services/app.ts'

// eslint-disable-next-line react-refresh/only-export-components
const BreakoutRoom = lazy(() => import('@/pages/SharedPage/BreakoutRoom'))

// eslint-disable-next-line react-refresh/only-export-components
const BreakoutPage = lazy(() => import('@/pages/SharedPage/BreakoutPage'))

// eslint-disable-next-line react-refresh/only-export-components
const AllStaffPage = lazy(() => import('@/pages/SharedPage/AllStaffPage'))
// eslint-disable-next-line react-refresh/only-export-components
const AllStudentPage = lazy(() => import('@/pages/SharedPage/AllStudentPage'))

// eslint-disable-next-line react-refresh/only-export-components
function Shared() {
  const { user } = useAuthContext()
  const { sidebar } = useAppContext()
  return (
    <>
      {user && hasRole(user, 'ROLE_EDCLASS_STAFF') ? (
        user.teachingSubjects?.length > 0 ? (
          <sidebar.In>
            <TeacherSidebar />
          </sidebar.In>
        ) : user.departments?.find((d) => d.flag === 'SG') !== undefined ? (
          <sidebar.In>
            <SafeguardingSidebar />
          </sidebar.In>
        ) : (
          <sidebar.In>
            <TeacherSidebar />
          </sidebar.In>
        )
      ) : null}
      <Suspense fallback={<>loading s page</>}>
        <Outlet />
      </Suspense>
    </>
  )
}

export default {
  path: PathKind.Shared,
  element: <Shared />,
  children: [
    {
      path: `${SharedChildrenPath.BREAKOUT_ROOM}/:id`,
      element: <BreakoutRoom />,
    },
    {
      path: `${SharedChildrenPath.BREAKOUT}`,
      element: <BreakoutPage />,
    },
    {
      path: `${SharedChildrenPath.STAFF}`,
      element: <AllStaffPage />,
    },
    {
      path: `${SharedChildrenPath.STUDENT}`,
      element: <AllStudentPage />,
    },
    recordRoute,
  ],
}

sidebarHiddenPaths.add(
  `/${PathKind.Shared}/${SharedChildrenPath.BREAKOUT_ROOM}/:id`,
)
sidebarHiddenPaths.add(
  `/${PathKind.Shared}/${SharedChildrenPath.BREAKOUT}/:id/*`,
)
