export enum SharedChildrenPath {
  BREAKOUT = 'breakout',
  BREAKOUT_ROOM = 'breakout-room',
  RECORD = 'records',
  STAFF = 'staffs',
  STUDENT = 'students',
}

export enum RecordChildrenPath {
  USER = 'users',
  SESSION = 'sessions',
  ROOM = 'rooms',
}
