import { VideoAspectRatio, VideoFillMode } from '@aws-sdk/client-ivs-realtime'
import memoize from 'fast-memoize'
import { CSSProperties } from 'react'

type RecorderMediaKind = 'mic' | 'camera' | 'screen' | 'camera_and_mic'
/*
const { mediaDevices, permissions } = navigator

function checkMediaDevicesSupport() {
  if (!mediaDevices) {
    throw new Error(
      'Media device permissions can only be requested in a secure context (i.e. HTTPS).',
    )
  }
}

export function stopMediaStream(mediaStream?: MediaStream) {
  const tracks = mediaStream?.getTracks() || []
  tracks.forEach((track) => track.stop())
}

export function noop() {}

function isFulfilled<T>(
  input: PromiseSettledResult<T>,
): input is PromiseFulfilledResult<T> {
  return input.status === 'fulfilled'
}

function isRejected(
  input: PromiseSettledResult<unknown>,
): input is PromiseRejectedResult {
  return input.status === 'rejected'
}
*/
export function queueMacrotask(task: VoidFunction) {
  setTimeout(task, 0)
}
/*
export async function requestUserMediaPermissions({
  onGranted = noop,
  onDenied = noop,
  deviceIds = {},
}: {
  onGranted?: (mediaStream?: MediaStream) => Promise<void> | void
  onDenied?: (error: Error) => void
  deviceIds?: { audio?: string; video?: string }
}) {
  let mediaStream: MediaStream | undefined
  let isGranted = false
  let error: Error | undefined

  try {
    const constraints: MediaStreamConstraints = {}
    checkMediaDevicesSupport()

    const [cameraPermissionQueryResult, microphonePermissionQueryResult] =
      await Promise.allSettled(
        ['camera', 'microphone'].map((permissionDescriptorName) =>
          permissions.query({
            name: permissionDescriptorName as PermissionName,
          }),
        ),
      )

    if (
      (isFulfilled(cameraPermissionQueryResult) &&
        cameraPermissionQueryResult.value.state !== 'granted') ||
      isRejected(cameraPermissionQueryResult)
    ) {
      constraints.video = {
        deviceId: { ideal: deviceIds.video || 'default' },
      }
    }

    if (
      (isFulfilled(microphonePermissionQueryResult) &&
        microphonePermissionQueryResult.value.state !== 'granted') ||
      isRejected(microphonePermissionQueryResult)
    ) {
      constraints.audio = {
        deviceId: { ideal: deviceIds.audio || 'default' },
      }
    }

    if (Object.keys(constraints).length) {
      mediaStream = await mediaDevices.getUserMedia(constraints)
    }

    isGranted = true
  } catch (e) {
    console.error(e)
    error = new Error((e as Error).name) // NotAllowedError + NotFoundError
  }

  if (isGranted) {
    await onGranted(mediaStream)
    stopMediaStream(mediaStream)
  } else {
    onDenied(error as Error)
  }
}

export async function enumerateDevices(): Promise<Devices> {
  try {
    checkMediaDevicesSupport()

    const devices = await mediaDevices.enumerateDevices()

    const videoInputDevices = devices.filter(
      ({ deviceId, kind }) => deviceId && kind === 'videoinput',
    )
    const audioInputDevices = devices.filter(
      ({ deviceId, kind }) => deviceId && kind === 'audioinput',
    )

    return { video: videoInputDevices, audio: audioInputDevices }
  } catch (error) {
    console.error(error)
    return { video: [], audio: [] }
  }
}*/

export function getRecorderMedia(kind: RecorderMediaKind) {
  switch (kind) {
    case 'mic':
      return getMicMedia()
    case 'camera':
      return getCameraMedia()
    case 'camera_and_mic':
      return getCameraAndMicMedia()
    case 'screen':
      return getScreenCaptureMedia()
  }
}
/*
export function getUserMedia({
  audioDeviceId,
  videoDeviceId,
}: {
  audioDeviceId?: string
  videoDeviceId?: string
}) {
  if (!audioDeviceId && !videoDeviceId) {
    return
  }

  checkMediaDevicesSupport()

  const constraints: EnhancedUserMediaStreamConstraints = {}

  if (videoDeviceId) {
    constraints.video = {
      deviceId: { exact: videoDeviceId }, // https://bugzilla.mozilla.org/show_bug.cgi?id=1443294#c7
      //aspectRatio: { ideal: 4 / 3 },
      //frameRate: { ideal: 30 },
      frameRate: { ideal: 15 },
      //width: 640,
      //height: 480,
      width: { ideal: 640 },
      height: { ideal: 480 },
      //width: { ideal: 720 },
      //height: { ideal: 720 },
      //width: { ideal: 1280 },
      //height: { ideal: 720 },
      facingMode: { ideal: 'user' },
      resizeMode: 'crop-and-scale',
    }
  }

  if (audioDeviceId) {
    constraints.audio = {
      deviceId: { exact: audioDeviceId },
    }
  }

  return mediaDevices.getUserMedia(constraints)
}

export function getDisplayMedia() {
  checkMediaDevicesSupport()

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const options: EnhancedDisplayMediaStreamOptions = {
    video: {
      cursor: 'always',
      resizeMode: 'crop-and-scale',
      frameRate: 15,
    },
    audio: {
      // The following audio constraints disable all browser audio processing
      // to prevent potential audio quality and low volume issues when screen
      // sharing tab audio.
      autoGainControl: false,
      echoCancellation: false,
      noiseSuppression: false,
    },
    // https://developer.chrome.com/docs/web-platform/screen-sharing-controls/
    selfBrowserSurface: 'include',
    surfaceSwitching: 'include',
    systemAudio: 'include',
    preferCurrentTab: false,
  }

  return mediaDevices.getDisplayMedia(options)
}
*/

function getCameraAndMicMedia() {
  return window.navigator.mediaDevices.getUserMedia({
    video: true, //{
    //width: { max: 720 },
    //height: { max: 480 },
    //},
    audio: true,
  })
}

function getCameraMedia() {
  return window.navigator.mediaDevices.getUserMedia({
    video: true /*{
      width: { max: 720 },
      frameRate: { ideal: 15 },
      //height: { max: 480 },
    },*/,
    audio: false,
  })
}

function getMicMedia() {
  return window.navigator.mediaDevices.getUserMedia({
    video: false,
    audio: true,
  })
}

function getScreenCaptureMedia() {
  return window.navigator.mediaDevices.getDisplayMedia({
    video: {
      width: { max: 1280 },
      height: { max: 720 },
    },
    audio: false,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore experimental feature, useful for Chrome
    selfBrowserSurface: undefined,
  })
}

const LAYOUT_CONFIG = {
  maxCols: 6,
  gridGap: 12,
  videoFillMode: VideoFillMode.COVER,
  videoAspectRatio: VideoAspectRatio.VIDEO,
}

interface BestFit {
  cols: number
  rows: number
  count: number
  itemWidth: number
  itemHeight: number
  containerWidth: number
  containerHeight: number
  cssAspectRatio?: CSSProperties['aspectRatio']
}

interface BestFitInput {
  count: number
  aspectRatio: number
  containerWidth: number
  containerHeight: number
}

interface RecursiveBestFitInput extends BestFitInput {
  maxBestFitAttempts: number
  maxItemAspectRatio: number
}

function exhaustiveSwitchGuard(value: never): never {
  throw new Error(
    `ERROR! Reached forbidden guard function with unexpected value: ${JSON.stringify(
      value,
    )}`,
  )
}

function getComputedVideoAspectRatio(videoAspectRatio: VideoAspectRatio) {
  switch (videoAspectRatio) {
    case VideoAspectRatio.AUTO:
      return 5 / 4 // Heuristic used to inform best-fit calculations for AUTO aspect ratios

    case VideoAspectRatio.VIDEO:
      return 16 / 9

    case VideoAspectRatio.SQUARE:
      return 1

    case VideoAspectRatio.PORTRAIT:
      return 3 / 4

    default:
      exhaustiveSwitchGuard(videoAspectRatio)
  }
}

function getCssVideoAspectRatio(
  videoAspectRatio: VideoAspectRatio,
): CSSProperties['aspectRatio'] {
  if (videoAspectRatio === VideoAspectRatio.AUTO) {
    return 'auto'
  }

  return getComputedVideoAspectRatio(videoAspectRatio)
}

function getRenderedVideoAspectRatio(isScreen = false) {
  /**
   * Return AUTO for screens (to use the screen's intrinsic aspect ratio)
   * otherwise, return the videoAspectRatio from the layout configuration.
   */
  return isScreen ? VideoAspectRatio.AUTO : LAYOUT_CONFIG.videoAspectRatio
}

function getRenderedVideoFillMode(isScreen = false) {
  /**
   * Return CONTAIN for screens (to ensure the entire screen is visible);
   * otherwise, return the videoFillMode from the layout configuration.
   */
  return isScreen ? VideoFillMode.CONTAIN : LAYOUT_CONFIG.videoFillMode
}

function isVideoResizedToFit(videoFillMode: VideoFillMode) {
  /**
   * Only CONTAIN resizes the video to stay contained within its container.
   */
  return videoFillMode === VideoFillMode.CONTAIN
}

function bestFitItemsToContainer(input: BestFitInput): BestFit {
  const { count, aspectRatio, containerWidth, containerHeight } = input

  if (!count || !aspectRatio || !containerWidth || !containerHeight) {
    return {
      cols: 0,
      rows: 0,
      itemWidth: 0,
      itemHeight: 0,
      count,
      containerWidth,
      containerHeight,
    }
  }

  const normalizedContainerWidth = containerWidth / aspectRatio
  const normalizedAspectRatio = normalizedContainerWidth / containerHeight
  const nColsFloat = Math.sqrt(count * normalizedAspectRatio)
  const nRowsFloat = count / nColsFloat

  // Find the best option that fills the entire height
  let nRows1 = Math.ceil(nRowsFloat)
  let nCols1 = Math.ceil(count / nRows1)
  while (nRows1 * normalizedAspectRatio < nCols1) {
    nRows1 += 1
    nCols1 = Math.ceil(count / nRows1)
  }

  // Find the best option that fills the entire width
  let nCols2 = Math.ceil(nColsFloat)
  let nRows2 = Math.ceil(count / nCols2)
  while (nCols2 < nRows2 * normalizedAspectRatio) {
    nCols2 += 1
    nRows2 = Math.ceil(count / nCols2)
  }

  const cellSize1 = containerHeight / nRows1
  const cellSize2 = normalizedContainerWidth / nCols2
  const cols = cellSize1 < cellSize2 ? nCols2 : nCols1
  const rows = Math.ceil(count / cols)
  const itemWidth = containerWidth / cols
  const itemHeight = containerHeight / rows

  return {
    count,
    cols,
    rows,
    itemWidth,
    itemHeight,
    containerWidth,
    containerHeight,
  }
}

export function getComputedMeetingGridStyle(isGridSplit: boolean) {
  const computedGridStyle: CSSProperties = { gap: LAYOUT_CONFIG.gridGap }

  if (isGridSplit) {
    computedGridStyle.gridTemplateRows = '70% auto'
  }

  return computedGridStyle
}

export function getComputedParticipantGridStyle(
  bestFit: BestFit,
  isScreen = false,
) {
  const { gridGap } = LAYOUT_CONFIG
  const videoAspectRatio = getRenderedVideoAspectRatio(isScreen)
  const computedParticipantGridStyle: React.CSSProperties = {
    gap: gridGap,
    margin: gridGap,
    gridTemplateColumns: `repeat(${bestFit.cols * 2}, minmax(0, 1fr))`,
    ...getComputedGridDimensions(bestFit, gridGap, videoAspectRatio),
  }

  return computedParticipantGridStyle
}

function getComputedGridDimensions(
  bestFit: BestFit,
  gridGap: number,
  videoAspectRatio: VideoAspectRatio,
) {
  const dimensions: React.CSSProperties = { height: '100%', maxWidth: '100%' }

  if (
    bestFit.rows > 0 &&
    bestFit.cols > 0 &&
    videoAspectRatio !== VideoAspectRatio.AUTO
  ) {
    const computedVideoAspectRatio =
      getComputedVideoAspectRatio(videoAspectRatio)

    const totalXGap = gridGap * (bestFit.cols - 1) // gap_size * num_col_gaps
    const totalYGap = gridGap * (bestFit.rows - 1) // gap_size * num_row_gaps
    const slotHeight = (bestFit.containerHeight - totalYGap) / bestFit.rows
    const slotWidth = slotHeight * computedVideoAspectRatio
    const totalSlotsWidth = slotWidth * bestFit.cols
    const maxSlotWidth = Math.min(
      bestFit.containerWidth,
      totalSlotsWidth + totalXGap,
    )

    delete dimensions.height
    dimensions.maxWidth = maxSlotWidth
  }

  return dimensions
}

export function getComputedGridSlotStyle(index: number, bestFit: BestFit) {
  const isFirstSlot = index === 0
  const isOneByTwo = bestFit.count === 2 && bestFit.cols === 1
  const isTwoByOne = bestFit.count === 2 && bestFit.cols === 2
  const remainingItemsOnLastRow = bestFit.count % bestFit.cols
  const computedSlotStyle: React.CSSProperties = {
    aspectRatio: bestFit.cssAspectRatio,
    gridColumnStart: 'span 2',
    gridColumnEnd: 'span 2',
  }

  // If needed, shift the last row to align it with the center of the grid
  if (bestFit.count - index === remainingItemsOnLastRow) {
    const shiftSlotRowBy = bestFit.cols - remainingItemsOnLastRow + 1
    computedSlotStyle.gridColumnStart = shiftSlotRowBy
  }

  // Position 1-by-2 and 2-by-1 grid LAYOUT_CONFIG participants side-by-side
  if (isOneByTwo) {
    computedSlotStyle.alignItems = isFirstSlot ? 'flex-end' : 'flex-start'
  } else if (isTwoByOne) {
    computedSlotStyle.justifyContent = isFirstSlot ? 'flex-end' : 'flex-start'
  }

  return computedSlotStyle
}

export function getComputedVideoStyle(isScreen = false) {
  const computedVideoStyle: React.CSSProperties = {}

  const objectFit = getRenderedVideoFillMode(isScreen).toLowerCase()
  computedVideoStyle.objectFit = objectFit as React.CSSProperties['objectFit']

  return computedVideoStyle
}

function recursiveBestFitItemsToContainer(input: RecursiveBestFitInput) {
  const { count, maxBestFitAttempts, maxItemAspectRatio } = input
  let bestFitAttempts = 0

  function runBestFit(currentCount: number) {
    const bestFit = bestFitItemsToContainer({ ...input, count: currentCount })
    const itemAspectRatio = bestFit.itemWidth / bestFit.itemHeight
    bestFitAttempts += 1

    if (
      bestFitAttempts < maxBestFitAttempts &&
      itemAspectRatio > maxItemAspectRatio
    ) {
      return runBestFit(currentCount + 1)
    }

    return bestFit
  }

  const bestFit = runBestFit(count)
  bestFit.count = count

  return bestFit
}

export const getBestFit = memoize(
  (count = 0, containerWidth = 0, containerHeight = 0, isScreen = false) => {
    const videoFillMode = getRenderedVideoFillMode(isScreen)
    const videoAspectRatio = getRenderedVideoAspectRatio(isScreen)
    const bestFitInput: BestFitInput = {
      count,
      containerWidth,
      containerHeight,
      aspectRatio: getComputedVideoAspectRatio(videoAspectRatio),
    }

    let bestFit: BestFit
    if (
      !isVideoResizedToFit(videoFillMode) &&
      videoAspectRatio === VideoAspectRatio.AUTO
    ) {
      const recursiveBestFitInput: RecursiveBestFitInput = {
        ...bestFitInput,
        maxBestFitAttempts: 4,
        maxItemAspectRatio: 3,
      }

      bestFit = recursiveBestFitItemsToContainer(recursiveBestFitInput)
    } else {
      bestFit = bestFitItemsToContainer(bestFitInput)
    }

    bestFit.cssAspectRatio = getCssVideoAspectRatio(videoAspectRatio)

    return bestFit
  },
  { strategy: memoize.strategies.variadic },
)
