import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import App from '@/App.tsx'
import AppSkeleton from '@/components/SkeletonLoading/AppSkeleton.tsx'
import { USER_CHECK_LOGIN } from '@/constants/auth.ts'
import { BASE_PATH, SIGN_IN_PATH } from '@/constants/constants.ts'
import useLoginCheck from '@/hooks/query/useLoginCheck.ts'
import useAuthContext from '@/hooks/useAuthProvider.ts'
import useEffectOnce from '@/hooks/useEffectOnce.ts'
import AppProvider from '@/providers/AppProvider.tsx'
import StudentsLocationProvider from '@/providers/StudentsLocationProvider'
import { getAuthService } from '@/services/auth.ts'

export default function ProtectedRoute() {
  const { user, logoutClean } = useAuthContext()
  const navigate = useNavigate()
  const noLoginCheck = localStorage?.getItem(USER_CHECK_LOGIN) !== '1'
  const [loading, setLoading] = useState(!noLoginCheck)

  const { data, isPending, isFetching } = useLoginCheck()

  useEffect(() => {
    if (!data && !(isPending || isFetching)) {
      alert('you will be logged-out')
    }
  }, [isPending, isFetching, data, logoutClean])

  const location = useLocation()

  useEffectOnce(() => {
    const shouldCheckLogin = localStorage?.getItem(USER_CHECK_LOGIN) ?? '1'
    if (!user) {
      // navigate user to login page if not signed in
      navigate(`${BASE_PATH}/${SIGN_IN_PATH}`, {
        state: {
          from: `${location.pathname}${location.search}`,
        },
      })
    } else if (user) {
      if (shouldCheckLogin === '1') {
        setLoading(true)
        getAuthService()
          .loginCheck()
          .then((res) => {
            if (res !== true) {
              /*logoutClean()
              navigate(`${BASE_PATH}/${SIGN_IN_PATH}`, {
                state: {
                  from: `${location.pathname}${location.search}`,
                },
              })*/
            }
          })
          .catch(() => {
            //logoutClean()
            /*navigate(`${BASE_PATH}/${SIGN_IN_PATH}`, {
              state: {
                from: location.pathname,
              },
            })*/
          })
          .finally(() => setLoading(false))
      } else {
        localStorage?.setItem(USER_CHECK_LOGIN, '1')
      }
    }
  })

  return loading ? (
    <AppSkeleton />
  ) : (
    <AppProvider>
      <StudentsLocationProvider>
        <App />
      </StudentsLocationProvider>
    </AppProvider>
  )
}
