import { Coordinates } from '@dnd-kit/utilities'
import { OffsetOptions, Placement } from '@floating-ui/react'
import { ReactNode, useMemo, useState } from 'react'

import AlertDialog from '@/components/Buttons/Fab/AlertDialog.tsx'
import Fab from '@/components/Buttons/Fab/index.tsx'
import StudentAlertModal from '@/components/Modal/StudentAlertModal.tsx'
import { EDIcon } from '@/constants/constants.ts'
import { hasRole } from '@/helpers/user.ts'
import useAuthContext from '@/hooks/useAuthProvider.ts'

export default function FloatingAlertButton({
  placement = 'bottom-start',
  offset = {
    mainAxis: 12,
  },
  dialogClassName,
  badge,
  size,
  ...rest
}: {
  placement?: Placement
  offset?: OffsetOptions
  dialogClassName?: string
  badge?: ReactNode
  size?: 'xl' | 'lg' | 'sm' | 'xs'
  draggable?: string
  position?: Coordinates
  defaultPosition?: {
    left: number
    bottom: number
  }
}) {
  const { user } = useAuthContext()
  const [open, setOpen] = useState(false)
  const isStudent = useMemo(() => {
    return hasRole(user!, 'ROLE_STUDENT')
  }, [user])

  const [alert, setAlert] = useState<AlertFormValue>({
    kind: 'safety',
    uri: window.location.origin,
    message: '',
    reported: [],
  })

  return (
    <Fab
      {...rest}
      icon={EDIcon.Alert}
      dialogClassName={dialogClassName}
      badge={badge}
      offset={offset}
      placement={placement}
      color="red"
      size={size}
      onClick={
        isStudent
          ? () => {
              console.log('open modal')
              setOpen(true)
            }
          : undefined
      }
    >
      {isStudent ? (
        <StudentAlertModal open={open} handler={setOpen} />
      ) : (
        <AlertDialog alert={alert} onChange={setAlert} />
      )}
    </Fab>
  )
}
