import { BASIC_COLORS, Sym } from '@edclass/fe-ui'
import {
  EDIvsParticipantAttribute,
  EDIvsParticipantInfo,
} from '@edclass/ivs-providers'
import { useAudioAlert } from '@edclass/react-providers'
import clsx from 'clsx'
import { AnimationControls } from 'framer-motion'
import { useEffect, useMemo, useRef, useState } from 'react'
import tinycolor from 'tinycolor2'

import Avatar from '@/components/Avatar'
import DraggableFab from '@/components/Buttons/Fab/DraggableFab.tsx'
import FloatingVideoButton from '@/components/Buttons/Fab/FloatingVideoButton.tsx'
import { ChatDialog, ChatDialogPanel } from '@/components/Chat/ChatDialog.tsx'
import { useVideoDialog } from '@/components/Video/VideoDialog.tsx'
import VideoStream from '@/components/Video/VideoStream.tsx'
import { AlertKeys, EDIcon } from '@/constants/constants.ts'
import { getUserDisplayName } from '@/helpers/user.ts'

function StreamPanel({
  user,
  userId,
  info,
  index,
}: {
  index: number
  userId: string
  info: EDIvsParticipantAttribute
  user?: MediaStream
}) {
  const clr = useMemo(() => {
    const c = BASIC_COLORS[index % BASIC_COLORS.length]
    const t = tinycolor(c)
    return t.darken(15).toHex8String()
  }, [index])
  return (
    <div
      className="relative"
      style={{
        backgroundColor: clr,
      }}
    >
      <VideoStream
        id={userId}
        placeholder={
          <div className="cc min-h-[160px] h-full">
            <Avatar size={48} className="shadow" user={info} />
          </div>
        }
        //className={clsx(user ? '!bg-ed-purple' : '!bg-indigo-500')}
        style={{
          backgroundColor: clr,
        }}
        autoPlay
        stream={user}
        forceUnmute
        size="auto"
        trackAudio
        className="!min-h-[160px] h-full"
      />
      <div className="bg-black/80 absolute bottom-0 text-white text-body p-2">
        {getUserDisplayName(info as unknown as User)}
      </div>
    </div>
  )
}

export function VideoDialogPanel({
  noDefaultClassName,
  className,
  displayStream,
  userStreams,
}: {
  className?: string
  noDefaultClassName?: boolean
  displayStream?: {
    display: MediaStream
    info: EDIvsParticipantAttribute
  }
  userStreams: EDIvsParticipantInfo[]
}) {
  /*const { streams } = useVideoDialog()
  const streamsArr: [
    string,
    {
      user?: MediaStream
      display?: MediaStream
      info: EDIvsParticipantAttribute
    },
  ][] = useMemo(() => {
    return Array.from(Array(10).keys()).map((i) => {
      return [
        i.toString(),
        {
          user: i % 3 === 0 ? new MediaStream() : undefined,
          //display: new MediaStream(),
          info: {
              id: i.toString(),
              email: 'asd@gmail.com',
              firstName: 'name',
              lastName: 'last',
              picture: undefined,
          } as EDIvsParticipantAttribute,
        },
      ]
    })
  }, [streams])*/

  const gridCols = useMemo(() => {
    if (userStreams.length <= 2) {
      return 'grid-cols-1'
    } else {
      return 'grid-cols-2'
    }
  }, [userStreams.length])
  return (
    <div
      className={clsx(
        !noDefaultClassName
          ? 'relative rounded-md bg-gray-100 text-ed-text h-full w-full flex flex-col gap-4 overflow-hidden'
          : '',
        className,
      )}
    >
      {displayStream ? (
        <div className="relative">
          <VideoStream
            className="w-full"
            size="md"
            stream={displayStream.display}
          />
          <div className="absolute bottom-0 flex-c-2 bg-black/80 text-white text-body p-2">
            <Sym>{EDIcon.ScreenShare}</Sym>{' '}
            {getUserDisplayName(
              displayStream.info.attributes as unknown as User,
            )}
          </div>
        </div>
      ) : null}
      <div className={clsx('h-full overflow-y-auto grid', gridCols)}>
        {userStreams.length > 0
          ? userStreams.map((stream, i) => {
              return (
                <StreamPanel
                  index={i}
                  userId={stream.userId || ''}
                  key={stream.userId}
                  info={stream.attributes}
                  user={stream.mediaStream}
                />
              )
            })
          : null}
      </div>
      {userStreams.length < 1 && (
        <div className="px-3 py-4">No active connections</div>
      )}
    </div>
  )
}

export default function DraggableVideoFab() {
  const [position, setPosition] = useState<{ x: number; y: number }>()
  const { open, setOpen, userStreams } = useVideoDialog()

  const { play, stop } = useAudioAlert()
  const [shake, setShake] = useState(false)
  const hasShaken = useRef(false)
  const [chatOpen, setChatOpen] = useState(false)

  useEffect(() => {
    if (!hasShaken.current && !open && userStreams.length > 0) {
      setShake(true)
      //const a = get(AlertKeys.CallIncoming)
      //console.log('mp3', a)
      //a?.play().catch(console.error)
      play(AlertKeys.CallIncoming, true)
      hasShaken.current = true // Prevent future shaking after first time
      //setTimeout(() => setShake(false), 600) // Stop shaking after ~3 shakes
    }
  }, [open, userStreams, play])

  useEffect(() => {
    if (open) {
      setShake(false)
      stop(AlertKeys.CallIncoming)
    }
  }, [open, stop])

  useEffect(() => {
    if (userStreams.length === 0 && hasShaken.current) {
      hasShaken.current = false
    }
  }, [userStreams])

  return (
    <DraggableFab setPosition={setPosition}>
      <FloatingVideoButton
        animate={
          shake
            ? ({
                x: [0, -3, 3, -3, 3, 0],
              } as unknown as AnimationControls)
            : undefined //{ x: 0 }
        }
        transition={{
          repeat: Infinity, // Loop the animation
          repeatType: 'loop',
          duration: 0.3,
          ease: 'easeInOut',
        }}
        draggable="ed_vfp"
        position={position}
        open={open}
        setOpen={setOpen}
        size="xs"
        headerAction={
          userStreams.length > 0 ? (
            <button
              onClick={() => {
                setChatOpen(!chatOpen)
              }}
            >
              <Sym className="!text-[20px]">{EDIcon.Chat}</Sym>
            </button>
          ) : undefined
        }
      >
        <div className={clsx(chatOpen && 'flex')}>
          <div
            className={clsx(
              'max-h-[500px] overflow-y-auto flex flex-col gap-4',
              chatOpen && 'w-[50%]',
            )}
          >
            <VideoDialogPanel userStreams={userStreams} noDefaultClassName />
          </div>
          {chatOpen && (
            <div className="w-[50%]">
              <ChatDialog>
                <ChatDialogPanel forceNoNav />
              </ChatDialog>
            </div>
          )}
        </div>
      </FloatingVideoButton>
    </DraggableFab>
  )
}
