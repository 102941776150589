import { Http } from '@/helpers/http.ts'
import { BaseService } from '@/services/base.ts'

enum AuthEndpoint {
  Login = 'login',
  LoginCheck = 'auth/check',
  Logout = 'auth/logout',
  Verify = 'login/new-password',
  Forgot = 'login/reset-password',
  ConfirmForgotPassword = 'login/confirm-password',
  Ping = 'ping',
}

export class AuthService extends BaseService {
  private static instance?: AuthService
  protected _client
  public static getInstance() {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService()
    }
    return AuthService.instance
  }

  private constructor(client?: Http) {
    super()
    if (client) {
      this._client = client
    } else {
      this._client = new Http({
        baseUrl: import.meta.env.VITE_AUTH_API_URL,
      })
    }
  }

  fixRole(data: unknown) {
    const user = data as User
    return {
      ...user,
      roles:
        Array.isArray(user.roles) && user.roles.length > 0
          ? user.roles
          : ['ROLE_EDCLASS_STAFF'],
    } as User
  }

  async login(username: string, password: string) {
    try {
      const res = await this._client.post(AuthEndpoint.Login, {
        username,
        password,
      })

      if (res.status >= 200 && res.status < 300) {
        return res.data
        //return this.fixRole(res.data)
      } else {
        return this.getError(res, res.status)
      }
    } catch (e) {
      return this.getError(e, 0)
    }
  }

  async loginCheck(): Promise<boolean | undefined> {
    try {
      const res = await this._client.get(AuthEndpoint.LoginCheck)
      if (res.status >= 200 && res.status < 300) {
        return true
      } else {
        return this.getError(res, res.status)
      }
    } catch (e) {
      return this.getError(e, 0)
    }
  }

  async logout() {
    try {
      await this._client.post(AuthEndpoint.Logout)
    } catch (e) {
      return this.getError(e, 0)
    }
  }

  async test() {
    try {
      await this._client.get(AuthEndpoint.Ping)
    } catch (e) {
      return this.getError(e, 0)
    }
  }

  async verifyPass(username: string, newPassword: string) {
    try {
      const res = await this._client.put(AuthEndpoint.Verify, {
        username,
        newPassword,
      })

      if (res.status >= 200 && res.status < 300) {
        return res.data
        //return this.fixRole(res.data.data)
      } else {
        return this.getError(res, res.status)
      }
    } catch (e) {
      return this.getError(e, 0)
    }
  }

  async forgotPass(username: string) {
    try {
      await this._client.post(AuthEndpoint.Forgot, {
        username,
      })
    } catch (e) {
      return this.getError(e, 0)
    }
  }

  async confirmForgotPass(
    username: string,
    password: string,
    confirmationCode: string,
  ) {
    try {
      const res = await this._client.post(AuthEndpoint.ConfirmForgotPassword, {
        username,
        password,
        confirmationCode,
      })

      if (res.status >= 200 && res.status < 300) {
        return res.data
        //return this.fixRole(res.data.data)
      } else {
        return this.getError(res, res.status)
      }
    } catch (e) {
      return this.getError(e, 0)
    }
  }
}

export function getAuthService() {
  return AuthService.getInstance()
}
