import { ComponentProps, useState } from 'react'

import DraggableFab from '@/components/Buttons/Fab/DraggableFab.tsx'
import FloatingAlertButton from '@/components/Buttons/Fab/FloatingAlertButton.tsx'

export default function DraggableAlertFab(
  props: ComponentProps<typeof FloatingAlertButton>,
) {
  const [position, setPosition] = useState<{
    x: number
    y: number
  }>()

  return (
    <DraggableFab setPosition={setPosition}>
      <FloatingAlertButton
        defaultPosition={{
          left: 9 * 16,
          bottom: 16,
        }}
        draggable="ed_afp"
        position={position}
        size="xs"
        {...props}
      />
    </DraggableFab>
  )
}
