import { Sym } from '@edclass/fe-ui'
import { IconButton, Spinner } from '@material-tailwind/react'
import clsx from 'clsx'
import { m } from 'framer-motion'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { uuidv7 } from 'uuidv7'

import Avatar from '@/components/Avatar'
import { useChatDialog } from '@/components/Chat/ChatDialog.tsx'
import ChatItem from '@/components/Chat/ChatItem.tsx'
import ChatVoicePlayer from '@/components/Chat/ChatVoicePlayer.tsx'
import Tooltip from '@/components/Tooltip'
import { CHAT_URL_REGEX } from '@/constants/constants.ts'
import { getExtension, getFileIcon, getThumbTypeFromStr } from '@/helpers/fs.ts'
import { errorToast } from '@/helpers/toast.tsx'
import useAppContext from '@/hooks/useAppProvider.ts'
import useAuthContext from '@/hooks/useAuthProvider.ts'
import { useMount } from '@/hooks/useMount.ts'
import useRecordMedia from '@/hooks/useRecordMedia.ts'
import { getFsService } from '@/services/fs.ts'

export default function ChatPanel({
  dark,
  className,
}: {
  dark?: boolean
  className?: string
}) {
  const { user } = useAuthContext()
  const isMounted = useMount()
  const { activeRoom, stateMap, isLoading, loadPreviousMessages, typingUsers } =
    useChatDialog()
  const [message, setMessage] = useState('')
  const curState = useMemo(() => {
    return activeRoom ? stateMap[activeRoom] : null
  }, [stateMap, activeRoom])

  //const typingTimeouts = useRef<{ [userId: string]: NodeJS.Timeout }>({})
  const typingResetTimeout = useRef<NodeJS.Timeout | null>(null)
  const typingStart = useRef<boolean>(false)

  const typingUserList = useMemo(() => {
    return activeRoom
      ? Object.entries(typingUsers[activeRoom] || {})
          .filter(([id, typing]) => {
            return id !== user?.id && Boolean(typing)
          })
          .map(([, user]) => {
            return user
          })
      : []
  }, [user, activeRoom, typingUsers])

  const isTyping = useMemo(() => {
    return typingUserList.length > 0
  }, [typingUserList])

  const {
    status,
    startRecording,
    stopRecording,
    mediaBlob,
    mediaBlobUrl,
    clearBlobUrl,
  } = useRecordMedia({
    audio: true,
  })
  const recentlySubmit = useRef(true)
  const scrolledTop = useRef(false)
  const messageContainerRef = useRef<HTMLDivElement>(null)
  const fileInput = useRef<HTMLInputElement>(null)
  const [files, setFiles] = useState<File[]>([])

  const { lastSgMessage } = useAppContext()
  const [lastReadMsg, setLastReadMsg] = useState<
    SgWsChatReadStatusResponse['detail']
  >({})

  useEffect(() => {
    if (
      lastSgMessage?.msg === 'chat' &&
      lastSgMessage.action === 'read-status' &&
      lastSgMessage._id.$oid === activeRoom
    ) {
      setLastReadMsg(lastSgMessage.detail)
    }
  }, [activeRoom, lastSgMessage])

  //const fileDiv = useRef<HTMLDivElement>(null)
  const [loading, setLoading] = useState(false)

  const handleSubmit = useCallback(async () => {
    if (loading) {
      return
    }

    typingStart.current = false
    curState?.room?.stopTyping(user?.id || '')
    if (typingResetTimeout.current) {
      clearTimeout(typingResetTimeout.current)
    }

    setLoading(true)

    let shouldSend = true
    const content: IvsChatContent = {
      type: 'text',
      msg: message,
    }

    const attrs = undefined
    if (files.length > 0 && user) {
      try {
        content.files = await getFsService().upload(files, {
          folder: `chat/${curState?.room?.roomId()}/${user.id}`,
        })
        content.type = 'files'
        shouldSend = true
      } catch (e) {
        errorToast((e as { message: string }).message)
        setLoading(false)
        shouldSend = false
      }
    }

    if (mediaBlobUrl && mediaBlob) {
      try {
        content.type = 'voice'
        const f = new File([mediaBlob], `${uuidv7()}.wav`)
        content.msg = await getFsService()
          .upload(f, {
            folder: `chat/${curState?.room?.roomId()}/${user?.id}`,
          })
          .then((res) => {
            return JSON.stringify(res[0])
          })
        shouldSend = true
      } catch (e) {
        errorToast((e as { message: string }).message)
        setLoading(false)
        shouldSend = false
      }
    }

    if (!message && content.type === 'text') {
      return
    }

    if (shouldSend) {
      curState?.room
        ?.sendMessage(JSON.stringify(content), attrs)
        .then(() => setMessage(''))
        .catch((e) => {
          errorToast(`${e}`)
        })
        .finally(() => {
          setMessage('')
          setFiles([])
          setLoading(false)
          clearBlobUrl()
          scrolledTop.current = false
          recentlySubmit.current = true
        })
    } else {
      setLoading(false)
    }
  }, [
    clearBlobUrl,
    loading,
    curState,
    files,
    mediaBlobUrl,
    mediaBlob,
    message,
    user,
  ])

  useEffect(() => {
    if (isMounted() && messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight
    }
  }, [messageContainerRef, isMounted])

  useEffect(() => {
    // Scroll to the bottom whenever the messages array changes
    if (messageContainerRef.current && !scrolledTop.current) {
      //recentlySubmit.current = false
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight
    }
    //
  }, [curState?.messages])

  useEffect(() => {
    const { current } = messageContainerRef

    const cb = () => {
      if (current) {
        if (current.scrollHeight > current.scrollTop) {
          //scrolledTop.current = true
        }
      }
    }

    current?.addEventListener('scroll', cb)
    return () => {
      current?.removeEventListener('scroll', cb)
    }
  }, [messageContainerRef])

  const embedContainer = useRef<HTMLDivElement>(null)
  const isEmbedding = useMemo(() => {
    return CHAT_URL_REGEX.test(message)
  }, [message])

  const [isLastMessageInView, setIsLastMessageInView] = useState(false)
  const lastMessageRef = useRef(null)
  const { sendSgMessage } = useAppContext()
  useEffect(() => {
    const { current } = lastMessageRef
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsLastMessageInView(true)
        } else {
          setIsLastMessageInView(false)
        }
      },
      { threshold: 0.1 },
    )

    if (current) {
      observer.observe(current)
    }

    return () => {
      if (current) {
        observer.unobserve(current)
      }
    }
  }, [curState?.messages])

  useEffect(() => {
    if (isLastMessageInView) {
      // Get the last key in the Map
      const keys = curState?.messages.keys()
      const lastMessageId = Array.from(keys || []).pop()
      if (lastMessageId && curState?.room && user) {
        const lastMessage = curState?.messages.get(lastMessageId)
        sendSgMessage({
          msg: 'chat',
          action: 'read-status',
          roomId: curState.room.roomId(),
          userId: user.id,
          messageSendTime: lastMessage
            ? new Date(lastMessage.sendTime)
            : new Date(),
          messageId: lastMessageId,
        })
      }
    }
  }, [sendSgMessage, user, isLastMessageInView, curState])

  return (
    <div
      style={{
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        '--embed-height': `${
          isEmbedding && embedContainer.current
            ? embedContainer.current.offsetHeight + 12
            : 0
        }px`,
        '--files-height': '0px', //`${fileDiv.current?.offsetHeight ? fileDiv.current?.offsetHeight + 12 : 0}px`,
        '--typing-height': `${isTyping ? 36 : 0}px`,
      }}
      className={clsx(
        'relative w-full overflow-hidden',
        dark ? 'dark' : '',
        className,
      )}
    >
      {curState?.curBackToken !== curState?.prevBackToken && curState?.room && (
        <button
          className="h-10 w-full rounded-full cc"
          onClick={() => {
            loadPreviousMessages(
              curState?.room.isBo(),
              curState?.room.roomId(),
              curState?.curBackToken,
            )
          }}
        >
          <div className="flex-c-2">
            {isLoading(curState?.room.roomId()) ? (
              <>
                <Spinner className="w-6 h-6" />
                <div>loading older messages</div>
              </>
            ) : (
              <>
                <Sym>history</Sym>
                <div>load older messages</div>
              </>
            )}
          </div>
        </button>
      )}
      <m.div
        /*initial={{ opacity: 0, y: 50 }}
        animate={{
          opacity: 1,
          y: 0,
          transition: { duration: 0.5, ease: 'easeOut' },
        }}*/
        ref={messageContainerRef}
        className={clsx(
          'h-[calc(100%-80px-1.5rem-3rem-var(--embed-height)-var(--typing-height))]',
          'overflow-y-auto flex flex-col gap-3 p-3',
        )}
      >
        {curState?.messages
          ? Array.from(curState.messages).map(([k, msg], i, arr) => {
              const owned = user?.id === msg.sender.userId

              return (
                <ChatItem
                  dark={dark}
                  lastReadMsg={owned ? lastReadMsg : null}
                  key={k}
                  ref={arr.length - 1 === i ? lastMessageRef : null}
                  sendTime={msg.sendTime}
                  id={msg.id}
                  message={msg.content}
                  sender={msg.sender}
                  direction={owned ? 'right' : 'left'}
                  createdAt={msg.sendTime}
                />
              )
            })
          : null}
      </m.div>
      {isTyping && (
        <div className="absolute bottom-[calc(80px+32px+2rem)] h-8 left-3 typing flex-c-2">
          <div className="stacked-sm inline-flex flex-wrap">
            {typingUserList.map((s, i) => {
              return (
                <Avatar
                  user={s || undefined}
                  className={clsx(
                    'border-[2px]',
                    dark ? 'border-black' : 'border-white',
                  )}
                  size={20}
                  src={`${import.meta.env.VITE_EVENT_API_URL}/api/data/avatar/by-id/${s}`}
                  key={i}
                />
              )
            })}
          </div>
          <Sym animateBeat>sms</Sym>
        </div>
      )}
      {/*filesArr.length > 0 && (
        <div
          ref={fileDiv}
          className="absolute bottom-[calc(80px+32px+2rem+var(--typing-height))] px-2 max-h-[100px] left-3 overflow-y-auto justify-end right-3 flex-c gap-1 flex-wrap text-body"
        >
          {filesArr.map((f, i) => {
            const icon = getFileIcon(
              getThumbTypeFromStr(
                getExtension({
                  key: f.name.toLowerCase(),
                } as unknown as FsItem),
              ),
            )
            return (
              <div key={i} className="bg-gray-300 rounded flex-c px-0.5">
                <Sym className="!text-[20px]">{icon}</Sym>
                <div>{f.name}</div>
              </div>
            )
          })}
        </div>
      )*/}
      <div className="absolute bottom-0 w-full p-3">
        {mediaBlobUrl ? (
          <ChatVoicePlayer
            dark={dark}
            src={mediaBlobUrl}
            onRemove={clearBlobUrl}
          />
        ) : status === 'recording' ? (
          <div
            className={clsx(
              'w-full h-[80px] flex-c justify-center gap-4 rounded-md p-2 mb-2',
              dark ? 'bg-gray-900' : 'bg-gray-300',
            )}
          >
            <Sym animateBeat className="!text-[24px]">
              graphic_eq
            </Sym>
            recording audio ...
          </div>
        ) : files.length > 0 ? (
          <div
            className={clsx(
              'overflow-y-auto',
              'flex-shrink-0 text-ed-text h-[80px] w-full rounded-md p-2 mb-2 flex-c gap-1 flex-wrap text-body justify-end',
              dark ? 'bg-gray-900' : 'bg-gray-300',
            )}
          >
            {files.map((f, i) => {
              const icon = getFileIcon(
                getThumbTypeFromStr(
                  getExtension({
                    key: f.name.toLowerCase(),
                  } as unknown as FsItem),
                ),
              )
              return (
                <div
                  key={i}
                  className={clsx(
                    ' rounded flex-c px-0.5',
                    dark ? 'bg-blue-gray-900' : 'bg-blue-gray-200',
                  )}
                >
                  <div className="flex-c-2 mr-2">
                    <Sym className="!text-[20px]">{icon}</Sym>
                    <div>{f.name}</div>
                  </div>
                  <button
                    onClick={() => {
                      setFiles((prev) => {
                        const copy = [...prev]
                        copy.splice(i, 1)
                        return copy
                      })
                    }}
                  >
                    <Sym className="!text-[20px]">close</Sym>
                  </button>
                </div>
              )
            })}
          </div>
        ) : (
          <div
            className={clsx(
              'flex-shrink-0 h-[80px] w-full rounded-md mb-2',
              dark ? 'bg-gray-900 text-white' : 'bg-gray-400/90 text-ed-text',
            )}
          >
            {/*isEmbedding && (
              <div
                ref={embedContainer}
                className="max-w-[80%] h-[100px] overflow-y-auto"
              >
                <ChatText text={message} />
              </div>
            )*/}
            <textarea
              onKeyDown={async (e) => {
                if (e.ctrlKey && e.key === 'Enter') {
                  await handleSubmit()
                }
              }}
              value={message}
              onChange={(e) => {
                setMessage(e.target.value)
                // Send typing indicator message
                if (typingResetTimeout.current) {
                  clearTimeout(typingResetTimeout.current)
                }

                if (!typingStart.current) {
                  typingStart.current = true
                  curState?.room?.startTyping(user?.id || '')
                }

                typingResetTimeout.current = setTimeout(() => {
                  typingStart.current = false
                  curState?.room?.stopTyping(user?.id || '')
                }, 2000)
              }}
              placeholder="Type your messages"
              className={clsx(
                'flex-shrink-0 text-body h-[80px] w-full rounded-md',
                'focus-visible:outline-none border-0 resize-none p-2 bg-transparent',
                dark ? 'text-white' : 'text-ed-text',
              )}
            />
          </div>
        )}
        <div className="flex-c justify-between h-[32px] overflow-y-hidden">
          <div className="flex-c-2">
            <button
              className={clsx(
                'transition-colors text-blue-gray-500',
                dark ? 'hover:text-white' : 'hover:text-black',
              )}
              onClick={() => {
                fileInput.current?.click()
              }}
            >
              <Sym>attach_file</Sym>
            </button>
            <input
              tabIndex={-1}
              multiple
              className="sr-only"
              type="file"
              ref={fileInput}
              accept="application/msword,application/vnd.ms-excel,application/pdf,text/*,image/*,video/*,audio/*"
              onChange={(e) => {
                const files = e.currentTarget.files
                const arr = []

                if (files) {
                  for (const f of files) {
                    arr.push(f)
                  }
                }

                setFiles(arr)
              }}
            />
            <IconButton
              size="sm"
              color="indigo"
              onClick={async () => {
                if (status !== 'recording') {
                  startRecording()
                } else if (status === 'recording') {
                  stopRecording()
                }
              }}
            >
              <Sym className="!text-[20px]">
                {status === 'recording' ? 'graphic_eq' : 'mic'}
              </Sym>
            </IconButton>
          </div>
          <div className="flex-c-2">
            {/*<span className="text-blue-gray-500 text-[10px]">Ctrl + Enter</span>*/}
            <Tooltip content="Send message or use Ctrl + Enter as shortcut">
              <IconButton
                size="sm"
                color="teal"
                disabled={loading}
                onClick={async () => {
                  await handleSubmit()
                }}
              >
                {loading ? (
                  <Spinner className="w-4 h-4" />
                ) : (
                  <Sym className="!text-[20px]">send</Sym>
                )}
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  )
}
