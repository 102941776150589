import {
  Gender,
  Pronoun,
  TEACHER_STAFF_PERMISSIONS,
  Timeline,
} from '@edclass/fe-common'
import { nanoid } from '@edclass/fe-ui'

import { TimetableSheetValue } from '@/components/TimetableSheet/TimetableSheetContext.ts'
import { SharedChildrenPath } from '@/constants/shared_pages.ts'
import { PathKind } from '@/helpers/path.ts'
import { TimetableSubject } from '@/helpers/types.ts'

export enum AlertKeys {
  Student = 'student',
  CallIncoming = 'call-incoming',
  CallJoined = 'call-joined',
}

//export const CHAT_URL_REGEX =
//  /(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}([/?][^\s]*)?/gi
export const CHAT_URL_REGEX =
  /(https?:\/\/[^\s]+|www\.[a-zA-Z0-9-]+\.[a-zA-Z]{2,}([/?][^\s]*)?|[a-zA-Z0-9-]+\.[a-zA-Z]{2,}([/?][^\s]*)?)/gi

export const DEFAULT_TZ = 'Europe/London'

export enum EDIcon {
  Holiday = 'featured_seasonal_and_gifts',
  Breakout = 'meeting_room',
  Recording = 'album',
  ScreenShare = 'screen_share',
  StopScreenShare = 'stop_screen_share',
  Note = 'note_stack',
  NoteAdd = 'note_stack_add',
  Comment = 'comment',
  Tour = 'tour',
  Export = 'ios_share',
  Skill = 'bolt',
  DAQ = 'glyphs',
  Skill2 = 'emoji_objects',
  Objective = 'target',
  Objective2 = 'view_object_track',
  Objective3 = 'flowsheet',
  AudioLib = 'library_music',
  ImageLib = 'photo_library',
  VideoLib = 'video_library',
  OtherLib = 'unknown_document',
  ExBoard = 'label',
  Archive = 'inventory_2',
  Prev = 'arrow_back',
  Next = 'arrow_forward',
  Grid = 'grid_on',
  Send = 'send',
  Police = 'local_police',
  Safety = 'health_and_safety',
  Career = 'person_celebrate',
  Person2 = 'person_play',
  Guardian = 'guardian',
  Therapy = 'physical_therapy',
  Leaderboard = 'leaderboard',
  Support = 'support_agent',
  PlayLesson = 'play_lesson',
  Email = 'email',
  Bell = 'notifications',
  Chat = 'chat',
  Forum = 'forum',
  Form = 'ballot',
  Setting = 'settings',
  Add = 'add',
  AddCircle = 'add_circle',
  Alert = 'warning',
  Attendance = 'free_cancellation',
  Behavior = 'psychology',
  Branch = 'account_tree',
  CMS = 'code',
  Clone = 'content_copy',
  Curriculum = 'history_edu',
  Essay = 'stylus_note',
  DND = 'swipe_up',
  Dashboard = 'dashboard',
  Delete = 'delete',
  Difficulty = 'speed',
  Doodle = 'gesture',
  EDLive = 'video_chat',
  EDTeach = 'headset_mic',
  Edit = 'edit_square',
  Education = 'cast_for_education',
  Generic = 'segment',
  Game = 'videogame_asset',
  GameBuilder = 'sports_esports',
  Group = 'group',
  Home = 'home',
  HomeVisit = 'location_away',
  Import = 'publish',
  Info = 'info',
  Profile = 'person',
  Lesson = 'interactive_space',
  Level = 'school',
  Log = 'description',
  Human = 'accessibility',
  Help = 'help',
  Manager = 'supervisor_account',
  Notification = 'notifications_active',
  Pathway = 'route',
  Pathway2 = 'directions',
  Permission = 'policy',
  Puzzle = 'extension',
  MCQ = 'quiz',
  Answer = 'edit_note',
  Question2 = 'question_mark',
  Report = 'lab_profile',
  Review = 'rate_review',
  Reward = 'trophy',
  Safeguarding = 'shield_person',
  School = 'source_environment',
  AcademicTerm = 'calendar_month',
  SchoolType = 'category',
  Score = 'scoreboard',
  ScoreBar = 'readiness_score',
  Seat = 'airline_seat_recline_normal',
  Setup = 'things_to_do',
  Stat = 'monitoring',
  Staff = 'diversity_2',
  Storage = 'folder_open',
  Stream = 'sensors',
  StudentLive = 'cinematic_blur',
  Subject = 'science',
  Subject2 = 'dictionary',
  Subscription = 'loyalty',
  Teacher = 'account_child_invert',
  Timeline = 'calendar_clock',
  Timetable = 'table_view',
  Table = 'table',
  Topic = 'list',
  Upload = 'cloud_upload',
  User = 'badge',
  Video = 'play_circle',
  View = 'visibility',
}

export const AO_DIVIDER = 10000
export const SKELETON_THEME = {
  baseColor: '#202442',
  highlightColor: '#292e58',
}

export const IS_DEBUG = import.meta.env.DEV
export const NOW = new Date()
export const BASE_PATH =
  import.meta.env.BASE_URL === '/' ? '' : import.meta.env.BASE_URL
export const ERROR_NOT_FOUND_PATH = '404'
export const SIGN_IN_PATH = 'sign-in'
export const SIGN_UP_PATH = 'sign-up'
export const MY_ACCOUNT_PATH = 'my-account'
export const SAMPLE_SUBJECTS: SubjectReqBody[] = [
  {
    title: 'Maths',
    icon: 'calculate',
    color: '#2C3E50',
  },
  {
    title: 'Science',
    icon: 'experiment',
    color: '#27AE60',
  },
  { title: 'English Literature', icon: 'local_library', color: '#8E44AD' },
  { title: 'English Language', icon: 'dictionary', color: '#3498DB' },
  { title: 'Behaviour Repair', icon: 'psychology', color: '#E74C3C' },
  { title: 'History', icon: 'history_edu', color: '#D35400' },
  { title: 'Geography', icon: 'globe', color: '#1ABC9C' },
  { title: 'Physical Education', icon: 'sports_handball', color: '#E67E22' },
  { title: 'PSHE', icon: 'accessibility_new', color: '#F39C12' },
  { title: 'Music', icon: 'music_note', color: '#9B59B6' },
  { title: 'Careers', icon: 'engineering', color: '#34495E' },
  { title: 'Prevent', icon: 'local_police', color: '#C0392B' },
  { title: 'British Values', icon: 'diversity_2', color: '#2980B9' },
  { title: 'E-safety', icon: 'health_and_safety', color: '#16A085' },
  { title: 'Gun and Knife Crime', icon: 'gavel', color: '#7F8C8D' },
]

export const SAMPLE_WONDE_IDS = [
  'A1340660301',
  'A441170123',
  'A1314926662',
  'A1925980428',
  'A2013508433',
  'A597513071',
]

export enum AdminChildrenPath {
  MARKING = 'marking',
  SYSTEM_PREF = 'system-preference',
  GAME = 'games',
  EX_BOARD = 'ex-boards',
  SCHOOL_TYPE = 'school-types',
  SEAT_FORM = 'seat-forms',
  QUESTIONNAIRE_FORM = 'questionnaire-forms',
  CURRICULUM = 'curriculums',
  SCHOOLS = 'schools',
  SUBJECTS = 'subjects',
  LEVELS = 'levels',
  TOPICS = 'topics',
  TEACHERS = 'teachers',
  LESSONS = 'lessons',
  REPORTING = 'reporting',
  STREAMS = 'streams',
  ALERTS = 'alerts',
  PATHWAYS = 'pathways',
  STAFFS = 'staffs',
  EDLIVE = 'ed-live',
  LOGS = 'logs',
  STORAGE = 'storage',
  CHANGE_LOG = 'change-log',
  IMPORT = 'import',
  SKILL = 'skills',
  ACTION = 'actions',
}

export enum AdminExBoardChildrenPath {
  GRADING = 'grades',
  OBJECTIVE = 'objectives',
}

export enum AdminSystemPrefChildrenPath {
  TOUR = 'tours',
  TUTORIAL = 'tutorials',
  HOLIDAY = 'holidays',
}

export enum AdminQuestionnaireFormChildrenPath {
  SECTION = 'sections',
  QUESTION = 'questions',
}

export enum AdminSeatFormChildrenPath {
  QUESTION = 'questions',
  GROUP = 'groups',
}

export enum AdminStorageChildrenPath {
  IMAGE = 'images',
  VIDEO = 'videos',
  AUDIO = 'audios',
  OTHER = 'others',
  MIGRATION = 'migrations',
}

export enum AdminLessonChildrenPath {
  QUESTION = 'questions',
  DND = 'dnd',
  GAME = 'games',
  PUZZLE = 'puzzles',
  DOODLE = 'doodles',
  IMPORT = 'import',
  REVISION = 'revisions',
  DAQ = 'daq',
}

export enum AdminSkillChildrenPath {
  SKILL_TAG = 'skill-tags',
  KEY_SKILL = 'key-skills',
  SUBJECT_SPECIFIC_SKILL = 'subject-specific-skill',
}

export enum AdminSubjectChildrenPath {
  LEGACY = 'legacy',
}

export enum AdminSchoolChildrenPath {
  BRANCH = 'branches',
  SEATS = 'seats',
  FORMS = 'forms',
  ACADEMIC_TERM = 'academic-terms',
  //STUDENT_QUESTIONS = 'student-questions',
  STUDENTS = 'students',
  SUBSCRIPTIONS = 'subscriptions',
  TIMETABLES = 'timetables',
  PATHWAYS = 'pathways',
}

export enum AdminCrudPath {
  CREATE = 'new',
  //EDIT = 'edit',
  REVIEW = 'review',
  ALLOCATE = 'allocate',
  DETAIL = 'detail',
}

export enum AdminLogChildrenPath {
  STATS = 'stats',
  REPORTS = 'reports',
  SEAT_BREAKDOWN = 'seat-breakdown',
}

export const ADMIN_SYSTEM_PREF_ROUTE = [
  {
    path: AdminSystemPrefChildrenPath.TOUR,
    pathKind: PathKind.Admin,
    label: 'Product Tours',
    icon: EDIcon.Tour,
    color: 'indigo',
  },
  {
    path: AdminSystemPrefChildrenPath.TUTORIAL,
    pathKind: PathKind.Admin,
    label: 'Tutorials',
    icon: EDIcon.Help,
    color: 'green',
  },
  {
    path: AdminSystemPrefChildrenPath.HOLIDAY,
    pathKind: PathKind.Admin,
    label: 'Holidays',
    icon: EDIcon.Holiday,
    color: 'blue',
  },
]

export const ADMIN_ROUTE = [
  /*
  {
    path: AdminChildrenPath.SEAT_FORM,
    pathKind: PathKind.Admin,
    label: 'Seat Forms',
    icon: EDIcon.Form,
  },
   */
  {
    path: AdminChildrenPath.SCHOOLS,
    pathKind: PathKind.Admin,
    label: 'Schools',
    icon: EDIcon.School,
    children: [
      /*
      {
        path: AdminChildrenPath.ACADEMIC_TERMS,
        pathKind: PathKind.Admin,
        label: 'Academic Terms',
        icon: EDIcon.AcademicTerm,
      },
       */
      /*{
        path: AdminSchoolChildrenPath.STUDENT_QUESTIONS,
        pathKind: PathKind.Admin,
        label: 'Student Questions',
        icon: EDIcon.Question2,
      },*/
    ],
  },
  {
    path: AdminChildrenPath.SUBJECTS,
    pathKind: PathKind.Admin,
    label: 'Subjects',
    icon: EDIcon.Subject,
    children: [
      {
        path: 'archives',
        pathKind: PathKind.Admin,
        label: 'Subjects Archive',
        icon: EDIcon.Archive,
      },
      {
        path: AdminSubjectChildrenPath.LEGACY,
        pathKind: PathKind.Admin,
        label: 'Legacy Subject',
        icon: EDIcon.Subject2,
      },
    ],
  },
  {
    path: AdminChildrenPath.TOPICS,
    pathKind: PathKind.Admin,
    label: 'Topics',
    icon: EDIcon.Topic,
    children: [
      {
        path: 'archives',
        pathKind: PathKind.Admin,
        label: 'Topics Archive',
        icon: EDIcon.Archive,
      },
    ],
  },
  {
    path: AdminChildrenPath.EX_BOARD,
    pathKind: PathKind.Admin,
    label: 'Exam Boards',
    icon: EDIcon.ExBoard,
    children: [
      {
        path: 'archives',
        pathKind: PathKind.Admin,
        label: 'Ex-Boards Archive',
        icon: EDIcon.Archive,
      },
    ],
  },
  {
    path: AdminChildrenPath.SKILL,
    pathKind: PathKind.Admin,
    label: 'Skills',
    icon: EDIcon.Skill,
    children: [
      {
        path: 'archives',
        pathKind: PathKind.Admin,
        label: 'Skills Archive',
        icon: EDIcon.Archive,
      },
      /*
      {
        path: AdminSkillChildrenPath.SKILL_TAG,
        pathKind: PathKind.Admin,
        label: 'Tags',
        icon: EDIcon.Objective,
      },
       */
      /*
      {
        path: AdminSkillChildrenPath.KEY_SKILL,
        pathKind: PathKind.Admin,
        label: 'Key Skills',
        icon: EDIcon.Objective2,
      },
      {
        path: AdminSkillChildrenPath.SUBJECT_SPECIFIC_SKILL,
        pathKind: PathKind.Admin,
        label: 'Subject Specific Skill',
        icon: EDIcon.Objective3,
      },
       */
    ],
  },
  {
    path: AdminChildrenPath.LESSONS,
    pathKind: PathKind.Admin,
    label: 'Lessons',
    icon: EDIcon.Lesson,
    children: [
      {
        path: 'archives',
        pathKind: PathKind.Admin,
        label: 'Lesson Archives',
        icon: EDIcon.Archive,
      },
      {
        path: AdminLessonChildrenPath.QUESTION,
        pathKind: PathKind.Admin,
        label: 'Questions',
        icon: EDIcon.MCQ,
        children: [
          {
            path: 'archives',
            pathKind: PathKind.Admin,
            label: 'Questions Archive',
            icon: EDIcon.Archive,
          },
        ],
      },
      /*
      {
        path: AdminLessonChildrenPath.DND,
        pathKind: PathKind.Admin,
        label: 'DND Items',
        icon: EDIcon.DND,
        children: [
          {
            path: 'archives',
            pathKind: PathKind.Admin,
            label: 'DND Archive',
            icon: EDIcon.Archive,
          },
        ],
      },
      {
        path: AdminLessonChildrenPath.PUZZLE,
        pathKind: PathKind.Admin,
        label: 'Puzzle Items',
        icon: EDIcon.Puzzle,
        children: [
          {
            path: 'archives',
            pathKind: PathKind.Admin,
            label: 'Puzzles Archive',
            icon: EDIcon.Archive,
          },
        ],
      },
      {
        path: AdminLessonChildrenPath.DOODLE,
        pathKind: PathKind.Admin,
        label: 'Doodle Items',
        icon: EDIcon.Doodle,
        children: [
          {
            path: 'archives',
            pathKind: PathKind.Admin,
            label: 'Doodles Archive',
            icon: EDIcon.Archive,
          },
        ],
      },
       */
      {
        path: AdminLessonChildrenPath.DAQ,
        pathKind: PathKind.Admin,
        label: 'DAQ',
        icon: EDIcon.DAQ,
        children: [
          /*
          {
            path: 'archives',
            pathKind: PathKind.Admin,
            label: 'Questions Archive',
            icon: EDIcon.Archive,
          },
           */
        ],
      },
      /*
      {
        path: AdminLessonChildrenPath.GAME,
        pathKind: PathKind.Admin,
        label: 'Game Items',
        icon: EDIcon.Game,
      },
       */
      /*{
        path: AdminLessonChildrenPath.IMPORT,
        pathKind: PathKind.Admin,
        label: 'Import',
        icon: EDIcon.Upload,
      },*/
    ],
  },
  {
    path: AdminChildrenPath.PATHWAYS,
    pathKind: PathKind.Admin,
    label: 'Pathways',
    icon: EDIcon.Pathway,
    children: [
      {
        path: 'archives',
        pathKind: PathKind.Admin,
        label: 'Pathways Archive',
        icon: EDIcon.Archive,
      },
    ],
  },
  {
    path: AdminChildrenPath.MARKING,
    pathKind: PathKind.Admin,
    label: 'Marking',
    icon: EDIcon.Score,
  },
  /*
  {
    path: AdminChildrenPath.ALERTS,
    pathKind: PathKind.Admin,
    label: 'Alerts',
    icon: EDIcon.Alert,
  },
   */
  /*
  {
    path: AdminChildrenPath.EDTEACH,
    pathKind: PathKind.Admin,
    label: 'EDTeach',
    icon: EDIcon.EDTeach,
  },
   */
  {
    path: AdminChildrenPath.STAFFS,
    pathKind: PathKind.Admin,
    label: 'Staffs',
    icon: EDIcon.Staff,
  },
  {
    path: AdminChildrenPath.LOGS,
    pathKind: PathKind.Admin,
    label: 'Logs',
    icon: EDIcon.Log,
    children: [
      {
        path: AdminLogChildrenPath.STATS,
        pathKind: PathKind.Admin,
        label: 'Stats',
        icon: EDIcon.Stat,
      },
      {
        path: AdminLogChildrenPath.REPORTS,
        pathKind: PathKind.Admin,
        label: 'Reports',
        icon: EDIcon.Report,
      },
      {
        path: AdminLogChildrenPath.SEAT_BREAKDOWN,
        pathKind: PathKind.Admin,
        label: 'Seat Breakdown',
        icon: EDIcon.Report,
      },
    ],
  },
  {
    path: AdminChildrenPath.STORAGE,
    pathKind: PathKind.Admin,
    label: 'Storage',
    icon: EDIcon.Storage,
    children: [
      {
        path: AdminStorageChildrenPath.IMAGE,
        icon: EDIcon.ImageLib,
        pathKind: PathKind.Admin,
        label: 'Images',
      },
      {
        path: AdminStorageChildrenPath.VIDEO,
        icon: EDIcon.VideoLib,
        pathKind: PathKind.Admin,
        label: 'Videos',
      },
      {
        path: AdminStorageChildrenPath.AUDIO,
        icon: EDIcon.AudioLib,
        pathKind: PathKind.Admin,
        label: 'Audios',
      },
      {
        path: AdminStorageChildrenPath.OTHER,
        icon: EDIcon.OtherLib,
        pathKind: PathKind.Admin,
        label: 'Others',
      },
    ],
  },
  {
    path: AdminChildrenPath.GAME,
    icon: EDIcon.GameBuilder,
    pathKind: PathKind.Admin,
    label: 'Games',
  },
  {
    path: AdminChildrenPath.SYSTEM_PREF,
    icon: EDIcon.Setting,
    pathKind: PathKind.Admin,
    label: 'System Preferences',
    children: ADMIN_SYSTEM_PREF_ROUTE,
  },
  {
    path: AdminChildrenPath.QUESTIONNAIRE_FORM,
    icon: EDIcon.Form,
    pathKind: PathKind.Admin,
    label: 'Questionnaire Form',
    children: [
      {
        path: AdminLessonChildrenPath.QUESTION,
        pathKind: PathKind.Admin,
        icon: EDIcon.MCQ,
        label: 'Questions',
      },
    ],
  },
  /*
  {
    path: AdminChildrenPath.IMPORT,
    pathKind: PathKind.Admin,
    label: 'Import',
    icon: EDIcon.Import,
  },
   */
]

export enum StudentChildrenPath {
  Independent = 'independent',
  Learning = 'learning',
  Pathway = 'pathway',
  Game = 'game',
  Timetable = 'timetable',
  TimetabledLesson = 'timetabled-lesson',
  Lesson = 'lesson',
  Report = 'report',
  Leaderboard = 'leaderboard',
  EDLive = 'edlive',
}

export const APP_MENU = [
  {
    target: '.app-menu-notif',
    title: 'Notification',
    content: 'Check your in-app notification here',
  },
  {
    target: '.app-menu-help',
    title: 'Knowledge Base',
    content:
      'Need help from time to time? use this menu to show you the current page tutorial',
  },
  {
    target: '.app-menu-user',
    title: 'User Profile',
    content: 'Where you can change your profile preferences',
  },
]
export const STUDENT_HOME_MENU_ROUTE = [
  {
    pathKind: PathKind.Student,
    path: StudentChildrenPath.Learning,
    label: 'Learning',
    color: 'lime',
    icon: EDIcon.Level,
    description: 'Your learning stuffs',
  },
  {
    pathKind: PathKind.Student,
    path: StudentChildrenPath.TimetabledLesson,
    label: 'Timetabled Lessons',
    badge: 'upcoming',
    color: 'brown',
    icon: EDIcon.Lesson,
    description: 'List of lesson in your current timetable',
  },
  {
    pathKind: PathKind.Student,
    path: StudentChildrenPath.EDLive,
    label: 'EDLive',
    color: 'light-blue',
    icon: EDIcon.EDLive,
    description: 'EDClass Live Streaming Classroom',
  },
  {
    pathKind: PathKind.Student,
    path: StudentChildrenPath.Pathway,
    label: 'Pathway',
    color: 'deep-orange',
    icon: EDIcon.Pathway,
    description: 'Your pathway plan',
  },
  {
    pathKind: PathKind.Student,
    path: StudentChildrenPath.Report,
    label: 'Reports',
    color: 'blue-gray',
    icon: EDIcon.Report,
    description: 'Reports for report',
  },
  {
    pathKind: PathKind.Student,
    path: StudentChildrenPath.Independent,
    label: 'Independent',
    color: 'teal',
    icon: EDIcon.Human,
    description: 'Independent study mode, pick whatever you want',
  },
  {
    pathKind: PathKind.Student,
    path: StudentChildrenPath.Game,
    label: 'Game',
    color: 'deep-purple',
    icon: EDIcon.Game,
    description: 'Game mode, pick whatever you want',
  },
  {
    pathKind: PathKind.Student,
    path: StudentChildrenPath.Leaderboard,
    label: 'Leaderboard',
    color: 'green',
    icon: EDIcon.Leaderboard,
    description: 'Game score leader board, get reward for your high score',
  },
  {
    pathKind: PathKind.Student,
    label: 'Online Safety',
    color: 'indigo',
    icon: EDIcon.Safety,
    description: 'Your safety issue',
  },
  {
    pathKind: PathKind.Student,
    label: 'Career',
    color: 'yellow',
    icon: EDIcon.Career,
    description: 'Let us talk about your career',
  },
  {
    pathKind: PathKind.Student,
    label: 'British Value',
    color: 'gray',
    icon: EDIcon.Staff,
    description: 'Be a british value',
  },
  {
    pathKind: PathKind.Student,
    label: 'Prevent',
    color: 'white',
    icon: EDIcon.Police,
    description: 'Help fight injustice and crime',
  },
]

export const STUDENT_MAIN_MENU_ROUTE = [
  {
    pathKind: PathKind.Student,
    color: 'blue',
    label: 'Marking',
    icon: EDIcon.Score,
    description: 'Marking in progress',
  },
  {
    pathKind: PathKind.Student,
    color: 'red',
    label: 'Alert',
    icon: EDIcon.Alert,
    description: 'Ask edclass staff for help',
  },
  {
    pathKind: PathKind.Student,
    color: 'indigo',
    label: 'Notifications',
    icon: EDIcon.Bell,
    description: 'View all of your notifications',
  },
  {
    pathKind: PathKind.Student,
    path: StudentChildrenPath.Timetable,
    color: 'purple',
    label: 'Timetable',
    badge: '16:40',
    icon: EDIcon.Timetable,
    description: 'View your active timetable',
  },
  {
    pathKind: PathKind.Student,
    color: 'green',
    label: 'Chat',
    badge: '5',
    icon: EDIcon.Chat,
    description: 'Open your chat logs',
  },
  {
    pathKind: PathKind.Student,
    color: 'cyan',
    label: 'Attendance',
    icon: EDIcon.User,
    description: 'Your attendance reports',
  },
]
export const STUDENT_ROUTE = [
  ...STUDENT_HOME_MENU_ROUTE.filter((p) => Boolean(p.path)),
  ...STUDENT_MAIN_MENU_ROUTE.filter((p) => Boolean(p.path)),
]

export enum SafeguardingChildrenPath {
  Day = 'day',
  EDTeach = 'ed-teach',
  Overview = 'overview',
}

export const SAFEGUARDING_ROUTE = [
  {
    path: SafeguardingChildrenPath.EDTeach,
    pathKind: PathKind.Safeguarding,
    label: 'EDTeach',
    icon: EDIcon.Support,
  },
  {
    path: SafeguardingChildrenPath.Overview,
    pathKind: PathKind.Safeguarding,
    label: 'Overview',
    icon: EDIcon.Report,
  },
]

export enum TeacherChildrenPath {
  BREAKOUT = 'breakout',
  EDTEACH = 'ed-teach',
  STUDENT = 'students',
  TIMETABLE = 'timetable',
  WEEKLY_OVERVIEW = 'weekly-overview',
  REPORTS = 'reports',
  SEAT_ALLOCATION = 'seat-allocation',
  SAFE_GUARDING = 'safe-guarding',
  ALERTS = 'alerts',
  HOME_VISITS = 'home-visits',
  STUDENT_PROFILE = 'student-profile',
  LEARNING_PATHWAYS = 'learning-pathways',
  ATTENDANCE = 'attendance',
  BEHAVIOUR = 'behaviour',
  SUBJECTS = 'subjects',
  REVIEWS = 'reviews',
  INSPECTION_REPORT = 'inspection-report',
  PERMISSIONS = 'permissions',
  SCHOOL_SETUP = 'school-setup',
  STAFF = 'staff',
  STUDENTS_LIVE = 'students-live',
  NOTIFICATION = 'notification',
  REWARDS = 'rewards',
  SUBSCRIPTION = 'subscription',
}

export const TEACHER_ROUTE = [
  {
    path: TeacherChildrenPath.EDTEACH,
    pathKind: PathKind.Teacher,
    label: 'EDTeach',
    icon: EDIcon.Support,
  },
  {
    path: TeacherChildrenPath.STUDENT,
    pathKind: PathKind.Shared,
    label: 'Students',
    icon: EDIcon.Group,
  },
  {
    path: SharedChildrenPath.STAFF,
    pathKind: PathKind.Shared,
    label: 'Staffs',
    icon: EDIcon.Staff,
  },
  {
    path: SharedChildrenPath.BREAKOUT,
    pathKind: PathKind.Shared,
    label: 'Breakout',
    icon: EDIcon.Breakout,
  },
  {
    path: TeacherChildrenPath.WEEKLY_OVERVIEW,
    pathKind: PathKind.Teacher,
    label: 'Overview',
    icon: EDIcon.Report,
  },
]

export const DUMMY_AVATAR = 'https://randomuser.me/api/portraits/women/56.jpg'

export const SUBJECT_TAILWIND_COLOR_CLASSNAME = {
  science: 'bg-ed-yellow',
  english: 'bg-ed-lime',
  history: 'bg-ed-green',
  geography: 'bg-ed-beige',
  math: 'bg-ed-orange',
  sport: 'bg-ed-purple',
  'i.t': 'bg-ed-brown',
  religion: 'bg-ed-blue',
  lunch: 'bg-ed-pink',
}

export type SubjectKind = keyof typeof SUBJECT_TAILWIND_COLOR_CLASSNAME
export const SUBJECT_DARK_FG = ['science', 'english']
export const EMPTY_TIMELINE: TimetableSubject[] = Object.values(Timeline).map(
  () => {
    return {
      id: nanoid(),
      subject: undefined,
      kidStage: undefined,
      isLeave: false,
    }
  },
)

export const EMPTY_TIMELINE_CATEGORIES: TimetableSheetValue<TimetableSubject> =
  {
    Monday: [...EMPTY_TIMELINE],
    Tuesday: [...EMPTY_TIMELINE],
    Wednesday: [...EMPTY_TIMELINE],
    Thursday: [...EMPTY_TIMELINE],
    Friday: [...EMPTY_TIMELINE],
  }

export const LOCALE = 'en-GB'

export enum FolderName {
  Lesson = 'lessons',
  Question = 'questions',
  Dnd = 'dnd-items',
  Game = 'games',
  GameExec = 'games/execs',
  GameAsset = 'games/assets',
}

export const GAME_TYPE_DESCRIPTIONS: Record<GameType | 'dnd', string> = {
  question: `Choose this, if the game requires questions bank, an options
                  will be added to the Game Item in the lesson builder for
                  editor to pick questions`,
  image: `Choose this, if the game requires questions bank, an options
                  will be added to the Game Item in the lesson builder for
                  editor to pick questions`,
  dnd: 'Select, if the game is a drag and drop game that require a question banks',
  none: `No questions bank will be added`,
}

export const DEFAULT_EDCLASS_STAFF_FORM_VALUE: StaffFormValue = {
  id: undefined,
  firstName: '',
  middleName: undefined,
  lastName: '',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  gender: Gender.Male,
  birthDate: new Date(Date.parse('1990-10-1')) ?? new Date(),
  email: '',
  permissions: [...TEACHER_STAFF_PERMISSIONS],
  roles: ['ROLE_EDCLASS_STAFF'],
  picture: '',
  username: '',
  pronoun: Pronoun.HeHimHis,
  phoneNumber: undefined,
  teachingSubjects: [],
}

export const DEFAULT_SCHOOL_STAFF_FORM_VALUE: SchoolStaffFormValue = {
  ...DEFAULT_EDCLASS_STAFF_FORM_VALUE,
  permissions: [],
  schoolId: '',
  roles: ['ROLE_SCHOOL_STAFF'],
}

export const DEFAULT_SCHOOL_STUDENT_FORM_VALUE: StudentFormValue = {
  ...DEFAULT_EDCLASS_STAFF_FORM_VALUE,
  permissions: [],
  birthDate: new Date(Date.parse('1990-10-1')) ?? new Date(),
  roles: ['ROLE_STUDENT'],
  yearGroup: 1,
  difficulty: 1,
}

export const DEFAULT_FLOATING_ANIMATION = {
  unmount: {
    opacity: 0,
    transformOrigin: 'top',
    transform: 'scale(0.95)',
    transition: { duration: 0.2 },
  },
  mount: {
    opacity: 1,
    transformOrigin: 'top',
    transform: 'scale(1)',
    transition: { duration: 0.2 },
  },
}
