import { Sym } from '@edclass/fe-ui'
import { useMemo } from 'react'

import { FileThumbSmall } from '@/components/Thumb/FileThumbSmall.tsx'
import Tooltip from '@/components/Tooltip'
import {
  FileIconType,
  getFileIcon,
  getThumbType,
  getThumbTypeFromStr,
} from '@/helpers/fs.ts'
import { getFsName } from '@/services/fs.ts'

export function FileDisplayThumb({ item }: { item: FsItem }) {
  const el = useMemo(() => {
    return <span className="fm-item-detail-small">{getFsName(item.key)}</span>
  }, [item])

  const thumbType: FileIconType = useMemo(() => {
    return getThumbType(item)
  }, [item])

  return <FileThumbSmall type={thumbType}>{el}</FileThumbSmall>
}

export function FileDisplaySmall({
  path,
  onRemove,
  className,
}: {
  path: string | File
  onRemove: () => void
  className?: string
}) {
  const thumbType: FileIconType = useMemo(() => {
    return typeof path === 'string' ? getThumbTypeFromStr(path) : 'unknown'
  }, [path])

  const realPath = useMemo(() => {
    if (path instanceof File) {
      return path.name
    } else {
      return path
    }
  }, [path])

  return (
    <Tooltip
      className="bg-blue-500 shadow"
      floatingClassName={className}
      content={
        <div className="break-words max-w-[400px] py-2 px-1.5">{realPath}</div>
      }
    >
      <div className="flex-c-2">
        <div className="flex items-center gap-1 min-w-0" aria-label={realPath}>
          <Sym className="!text-[18px]">{getFileIcon(thumbType)}</Sym>
          <div className="text-body truncate">{realPath}</div>
        </div>
        <button
          title="Clear"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            onRemove()
          }}
        >
          <Sym className="!text-[20px]">cancel</Sym>
        </button>
      </div>
    </Tooltip>
  )
}
