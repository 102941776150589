import { lazy, Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import {
  AdminChildrenPath,
  AdminSystemPrefChildrenPath,
} from '@/constants/constants.ts'

// eslint-disable-next-line react-refresh/only-export-components
const Index = lazy(() => {
  return import('@/pages/AdminPage/AdminSystemPrefPage/index.tsx')
})

// eslint-disable-next-line react-refresh/only-export-components
const Holiday = lazy(() => {
  return import('@/pages/AdminPage/AdminSystemPrefPage/Holiday.tsx')
})

// eslint-disable-next-line react-refresh/only-export-components
const Tour = lazy(() => {
  return import('@/pages/AdminPage/AdminSystemPrefPage/Tour.tsx')
})

// eslint-disable-next-line react-refresh/only-export-components
const Tutorial = lazy(() => {
  return import('@/pages/AdminPage/AdminSystemPrefPage/Tutorial.tsx')
})

export default {
  path: AdminChildrenPath.SYSTEM_PREF,
  element: (
    <Suspense>
      <Outlet />
    </Suspense>
  ),
  children: [
    {
      index: true,
      element: <Index />,
    },
    {
      path: AdminSystemPrefChildrenPath.HOLIDAY,
      element: <Holiday />,
    },
    {
      path: AdminSystemPrefChildrenPath.TOUR,
      element: <Tour />,
    },
    {
      path: AdminSystemPrefChildrenPath.TUTORIAL,
      element: <Tutorial />,
    },
  ],
}
