import { useDraggable } from '@dnd-kit/core'
import { Coordinates } from '@dnd-kit/utilities'
import { SquircleButton, Sym } from '@edclass/fe-ui'
import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingPortal,
  offset as fuiOffset,
  OffsetOptions,
  Placement,
  size as fuiSize,
  useClick,
  useFloating,
  useFocus,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import type { color } from '@material-tailwind/react/types/components/button'
import clsx from 'clsx'
import {
  AnimatePresence,
  AnimationControls,
  domAnimation,
  LazyMotion,
  m,
  TargetAndTransition,
  Transition,
  VariantLabels,
} from 'framer-motion'
import { CSSProperties, ReactNode, useMemo, useState } from 'react'

import { DEFAULT_FLOATING_ANIMATION } from '@/constants/constants.ts'
import { queueMacrotask } from '@/helpers/media.ts'

function DraggableWrapper({
  id,
  position,
  children,
  defaultPosition = {
    left: 16,
    bottom: 16,
  },
}: {
  id: string
  position?: Coordinates
  defaultPosition?: {
    left: number
    bottom: number
  }
  children?: ReactNode
}) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id,
  })

  // Calculate the top position relative to window height
  /*const [, setWindowHeight] = useState(document.documentElement.clientHeight)

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      setWindowHeight(document.documentElement.clientHeight)
    })

    console.log()
    // Observe the entire document for resizing
    resizeObserver.observe(document.documentElement)

    return () => {
      resizeObserver.disconnect()
    }
  }, [])*/

  const style = {
    position: 'fixed',
    zIndex: 100000,
    left: `${defaultPosition.left + (position?.x ?? 0)}px`,
    bottom: `${defaultPosition.bottom + Math.abs(position?.y ?? 0)}px`,
    transform: transform
      ? `translate(${transform.x}px, ${transform.y}px)`
      : undefined,
  }

  return (
    <div
      ref={setNodeRef}
      className="flex-c-2"
      {...attributes}
      {...listeners}
      style={style as CSSProperties}
    >
      {children}
    </div>
  )
}

function Wrapper({ children }: { children?: ReactNode }) {
  return <>{children}</>
}

export default function Fab({
  placement = 'bottom-start',
  offset = {
    mainAxis: 12,
  },
  dialogClassName,
  badge,
  icon,
  children,
  dialogWidth,
  dialogHeight,
  dialogMaxHeight,
  color,
  className,
  onClick,
  size = 'sm',
  open: openExternal,
  setOpen: setOpenExternal,
  position,
  draggable,
  animate,
  transition,
  defaultPosition,
}: {
  placement?: Placement
  offset?: OffsetOptions
  dialogClassName?: string
  className?: string
  badge?: ReactNode
  icon: string
  dialogWidth?: number
  dialogHeight?: number
  dialogMaxHeight?: number
  children?: ReactNode
  color?: color
  onClick?: () => void
  size?: 'xl' | 'lg' | 'sm' | 'xs'
  open?: boolean
  setOpen?: SetState<boolean>
  dismiss?: boolean
  position?: Coordinates
  draggable?: string
  animate?: AnimationControls | TargetAndTransition | VariantLabels | boolean
  transition?: Transition
  defaultPosition?: {
    left: number
    bottom: number
  }
}) {
  const [openInternal, setOpenInternal] = useState(false)

  const setOpen = useMemo(() => {
    return openExternal !== undefined && setOpenExternal !== undefined
      ? setOpenExternal
      : setOpenInternal
  }, [setOpenExternal, openExternal, setOpenInternal])

  const open = useMemo(() => {
    return openExternal !== undefined ? openExternal : openInternal
  }, [openExternal, openInternal])

  const { strategy, x, y, refs, floatingStyles, context } = useFloating({
    open,
    onOpenChange(open: boolean) {
      queueMacrotask(() => setOpen(open))
    },
    placement,
    whileElementsMounted: autoUpdate,
    middleware: [
      fuiOffset(offset),
      flip({ padding: 16 }),
      fuiSize({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        apply({ elements }: any) {
          Object.assign(elements?.floating?.style, {
            zIndex: 99,
          })
        },
        padding: 16,
      }),
    ],
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context, {
      enabled: true,
    }),
    useFocus(context),
    useRole(context, { role: 'dialog' }),
    /*
    useDismiss(context, {
      escapeKey: true,
      enabled: true,
    }),
     */
  ])

  const props = onClick
    ? getReferenceProps({
        className,
      })
    : {}

  const Wp = draggable ? DraggableWrapper : Wrapper
  return (
    <Wp id={draggable!} position={position} defaultPosition={defaultPosition}>
      <LazyMotion features={domAnimation}>
        <AnimatePresence>
          <m.div animate={animate} transition={transition}>
            <div ref={onClick ? undefined : refs.setReference} {...props}>
              <SquircleButton
                badge={badge}
                onClick={onClick ? onClick : () => setOpen(!open)}
                size={size}
                color={color}
              >
                <Sym>{icon}</Sym>
              </SquircleButton>
            </div>
            {!onClick ? (
              <FloatingPortal root={document.getElementById('portal')}>
                <LazyMotion features={domAnimation}>
                  <AnimatePresence>
                    {open && (
                      <FloatingFocusManager context={context} modal={false}>
                        <m.div
                          initial="unmount"
                          exit="unmount"
                          ref={refs.setFloating}
                          animate={open ? 'mount' : 'unmount'}
                          variants={DEFAULT_FLOATING_ANIMATION}
                          style={floatingStyles}
                          {...getFloatingProps({
                            className: clsx(
                              'w-[var(--fab-dialog-width)] h-[var(--fab-dialog-height)] max-h-[var(--fab-dialog-max-height)]',
                              dialogClassName,
                            ),
                            style: {
                              position: strategy,
                              top: y ?? 0,
                              left: x ?? 0,
                              overflow: 'auto',
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              '--fab-dialog-width': dialogWidth
                                ? `${dialogWidth}px`
                                : '400px',
                              '--fab-dialog-height': dialogHeight
                                ? `${dialogHeight}px`
                                : 'auto',
                              '--fab-dialog-max-height': dialogMaxHeight
                                ? `${dialogMaxHeight}px`
                                : 'auto',
                            },
                          })}
                        >
                          {children}
                        </m.div>
                      </FloatingFocusManager>
                    )}
                  </AnimatePresence>
                </LazyMotion>
              </FloatingPortal>
            ) : (
              children
            )}
          </m.div>
        </AnimatePresence>
      </LazyMotion>
    </Wp>
  )
}
