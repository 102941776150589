import { useMemo } from 'react'

export default function useIsQueryLoading(
  {
    isPending,
    isPlaceholderData,
  }: {
    isPending: boolean
    isPlaceholderData: boolean
  },
  {
    areParamsEqual,
  }: {
    areParamsEqual: Array<() => boolean> | (() => boolean)
  },
) {
  return useMemo(() => {
    const areEqual = Array.isArray(areParamsEqual)
      ? areParamsEqual.every((v) => v())
      : areParamsEqual()

    return isPending || (!areEqual && isPlaceholderData)
  }, [areParamsEqual, isPending, isPlaceholderData])
}
