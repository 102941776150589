import { useAudioAlert } from '@edclass/react-providers'
import clsx from 'clsx'
import { AnimationControls } from 'framer-motion'
import { ComponentProps, useEffect, useMemo, useRef, useState } from 'react'

import DraggableFab from '@/components/Buttons/Fab/DraggableFab.tsx'
import { VideoDialogPanel } from '@/components/Buttons/Fab/DraggableVideoFab.tsx'
import FloatingChatButton from '@/components/Buttons/Fab/FloatingChatButton.tsx'
import {
  ChatDialogPanel,
  useChatDialog,
} from '@/components/Chat/ChatDialog.tsx'
import { useVideoDialog } from '@/components/Video/VideoDialog.tsx'
import { AlertKeys } from '@/constants/constants.ts'

export default function DraggableChatFab({
  children,
  ...props
}: ComponentProps<typeof FloatingChatButton>) {
  const [position, setPosition] = useState<{
    x: number
    y: number
  }>()
  const { open, setOpen } = useChatDialog()

  const { play, stop } = useAudioAlert()
  const [shake, setShake] = useState(false)
  const hasShaken = useRef(false)
  const { userStreams, displayStreams } = useVideoDialog()
  const displayStream = useMemo(() => {
    const stream = displayStreams.find((d) => Boolean(d.mediaStream))
    if (!stream) {
      return
    }

    return {
      display: stream.mediaStream!,
      info: stream.attributes,
    }
  }, [displayStreams])

  useEffect(() => {
    if (!hasShaken.current && !open && userStreams.length > 0) {
      setShake(true)
      //const a = get(AlertKeys.CallIncoming)
      //console.log('mp3', a)
      //a?.play().catch(console.error)
      play(AlertKeys.CallIncoming, true)
      hasShaken.current = true // Prevent future shaking after first time
      //setTimeout(() => setShake(false), 600) // Stop shaking after ~3 shakes
    }
  }, [open, userStreams, play])

  useEffect(() => {
    if (open) {
      setShake(false)
      stop(AlertKeys.CallIncoming)
    }
  }, [open, stop])

  useEffect(() => {
    if (userStreams.length === 0 && hasShaken.current) {
      hasShaken.current = false
    }
  }, [userStreams])

  return (
    <DraggableFab setPosition={setPosition}>
      <FloatingChatButton
        animate={
          shake
            ? ({
                x: [0, -3, 3, -3, 3, 0],
              } as unknown as AnimationControls)
            : undefined //{ x: 0 }
        }
        transition={{
          repeat: Infinity, // Loop the animation
          repeatType: 'loop',
          duration: 0.3,
          ease: 'easeInOut',
        }}
        defaultPosition={{
          left: 5 * 16,
          bottom: 16,
        }}
        dialogWidth={userStreams.length > 0 ? 800 : undefined}
        dialogHeight={590}
        draggable="ed_cfp"
        position={position}
        open={open}
        setOpen={setOpen}
        size="xs"
        {...props}
      >
        <ChatDialogPanel
          extra={
            userStreams.length > 0 ? (
              <div
                className={clsx(
                  'extra max-h-[600px] overflow-y-auto flex flex-col gap-4',
                  'w-[50%] border-r border-gray-400',
                  userStreams.length === 1 && 'cc',
                )}
              >
                <VideoDialogPanel
                  displayStream={displayStream}
                  userStreams={userStreams}
                  noDefaultClassName
                />
              </div>
            ) : undefined
          }
        />
        {children}
      </FloatingChatButton>
    </DraggableFab>
  )
}
