import { useContextOrThrow } from '@edclass/react-providers'
import { createContext, ReactNode, useCallback, useEffect, useRef } from 'react'

import { hasRole } from '@/helpers/user.ts'
import useAppContext from '@/hooks/useAppProvider.ts'
import useAuthContext from '@/hooks/useAuthProvider.ts'

type StudentsLocationContextValue = {
  get: (id: string) => SgGeolocationCoordinates | undefined
  set: (id: string, value: SgGeolocationCoordinates) => void
}
const StudentsLocationContext =
  createContext<StudentsLocationContextValue | null>(null)
StudentsLocationContext.displayName = 'StudentsLocationContext'

// eslint-disable-next-line react-refresh/only-export-components
export function useStudentsLocation() {
  return useContextOrThrow(StudentsLocationContext)
}

export default function StudentsLocationProvider({
  children,
}: {
  children?: ReactNode
}) {
  const { user } = useAuthContext()
  const { lastSgMessage } = useAppContext()
  const positions = useRef(new Map())

  useEffect(() => {
    if (
      lastSgMessage?.msg === 'safeguarding' &&
      lastSgMessage.action === 'student-location' &&
      user &&
      hasRole(user, 'ROLE_EDCLASS_STAFF')
    ) {
      positions.current.set(lastSgMessage.studentId, lastSgMessage)
    }
  }, [lastSgMessage, user])

  const get = useCallback((id: string) => {
    return positions.current.get(id)
  }, [])

  const set = useCallback((id: string, geo: SgGeolocationCoordinates) => {
    return positions.current.set(id, geo)
  }, [])

  return (
    <StudentsLocationContext.Provider
      value={{
        get,
        set,
      }}
    >
      {children}
    </StudentsLocationContext.Provider>
  )
}
