import { useState } from 'react'

import SidebarMenu from '@/components/Sidebar/SidebarMenu.tsx'
import SymbolNav from '@/components/SymbolNav'
import { EDIcon, SAFEGUARDING_ROUTE } from '@/constants/constants.ts'
import { PathKind } from '@/helpers/path.ts'
import useAppContext from '@/hooks/useAppProvider.ts'

export default function SafeguardingSidebar() {
  const { sidebarCollapsed } = useAppContext()
  const [openedDd, setOpenedDd] = useState({})
  return (
    <SidebarMenu
      openedDd={openedDd}
      setOpenedDd={setOpenedDd}
      collapsed={sidebarCollapsed}
      className="sg-nav"
      depth={0}
      before={
        <SymbolNav end pathKind={PathKind.Safeguarding} icon={EDIcon.Dashboard}>
          Dashboard
        </SymbolNav>
      }
      items={SAFEGUARDING_ROUTE}
    />
  )
}
