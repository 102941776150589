import { Coordinates } from '@dnd-kit/utilities'
import { OffsetOptions, Placement } from '@floating-ui/react'
import {
  AnimationControls,
  TargetAndTransition,
  Transition,
  VariantLabels,
} from 'framer-motion'
import { ReactNode } from 'react'

import Fab from '@/components/Buttons/Fab/index.tsx'
import { EDIcon } from '@/constants/constants.ts'

export default function FloatingChatButton({
  placement = 'bottom-start',
  offset = {
    mainAxis: 12,
  },
  dialogClassName,
  badge,
  size,
  children,
  open,
  setOpen,
  ...rest
}: {
  placement?: Placement
  offset?: OffsetOptions
  dialogClassName?: string
  badge?: ReactNode
  size?: 'xl' | 'lg' | 'sm' | 'xs'
  children?: ReactNode
  open?: boolean
  setOpen?: SetState<boolean>
  draggable?: string
  position?: Coordinates
  animate?: AnimationControls | TargetAndTransition | VariantLabels | boolean
  transition?: Transition
  dialogHeight?: number
  dialogMaxHeight?: number
  dialogWidth?: number
  defaultPosition?: {
    left: number
    bottom: number
  }
}) {
  return (
    <Fab
      {...rest}
      icon={EDIcon.Chat}
      dialogClassName={dialogClassName}
      badge={badge}
      offset={offset}
      placement={placement}
      color="teal"
      size={size}
      open={open}
      setOpen={setOpen}
    >
      {children}
    </Fab>
  )
}
