import { Http } from '@/helpers/http.ts'
import { BaseService } from '@/services/base.ts'
import { Params } from '@/services/params.ts'

const BASE_URL: string = import.meta.env.VITE_SG_API_URL
export const API_VERSION = 'v1'

class SgService extends BaseService {
  private static instance: SgService
  protected _client: Http

  constructor() {
    super()
    this._client = new Http({
      baseUrl: `${BASE_URL}`,
    })
  }

  public static getInstance() {
    if (!SgService.instance) {
      SgService.instance = new SgService()
    }
    return SgService.instance
  }

  sendStudentAlert() {
    return this.send(this._client.post(`alerts/students`))
  }

  sendAlert(body: Record<string, unknown>) {
    return this.send(this._client.post(`alerts`, body))
  }

  listStudent(params?: Params) {
    return this.sendParams<
      PaginatedItems<{
        user: Student
        room: IvsRoomSummary
        chatRoom: SgChatRoom
      }>
    >(`data/students`, params)
  }

  listEDTeachStudentOnline(params?: Params) {
    return this.sendParams<
      PaginatedItems<{
        user: Student
        room: IvsRoomSummary
        chatRoom: SgChatRoom
      }>
    >(`data/students/edteach/online/search`, params)
  }

  listStudentNotes(id: string, params?: Params) {
    return this.sendParams<PaginatedItems<StudentNote>>(
      `students/notes/${id}`,
      params,
    )
  }
  addStudentNote(body: StudentNoteReqBody) {
    return this.send<StudentNote>(this._client.post(`students/notes`, body))
  }
  updateStudentNote(body: StudentNoteReqBody) {
    return this.send<StudentNote>(
      this._client.post(`students/notes/${body._id?.$oid}`, body),
    )
  }
  deleteStudentNote(id: Oid) {
    return this.send<void>(this._client.delete(`students/notes/${id.$oid}`))
  }
  listTag(params?: Params) {
    return this.sendParams<PaginatedItems<Tag>>(`tags`, params)
  }
  addTag(body: TagReqBody) {
    return this.send<StudentNote>(this._client.post(`tags`, body))
  }
  updateTag(body: TagReqBody) {
    return this.send<StudentNote>(
      this._client.post(`tags/${body._id?.$oid}`, body),
    )
  }
  deleteTag(id: Oid) {
    return this.send<void>(this._client.delete(`tags/${id.$oid}`))
  }
  listEDIncludeStudentOnline() {
    return this.send<
      PaginatedItems<{
        student: Student
        room: IvsRoomSummary
      }>
    >(this._client.get(`data/students/edinclude/online/search`))
  }

  createOrJoinOwnStreamRoom() {
    return this.send<SgJoinRoomResponse>(this._client.post(`streams`))
  }
  joinOtherStreamRoom(roomId: string) {
    return this.send<SgJoinRoomResponse>(
      this._client.post(`streams/${roomId}/join`),
    )
  }
  createOrJoinOwnChatRoom() {
    return this.send<SgChatRoom>(this._client.post(`chats`))
  }
  joinOtherChatRoom(roomId: string) {
    return this.send<SgChatRoomWithOwner>(
      this._client.post(`chats/${roomId}/join`),
    )
  }

  joinStudentRoomByRoomId(roomId: string) {
    return this.send<SgJoinRoomResponse>(
      this._client.post(`rooms/student/${roomId}/join`),
    )
  }

  /*r(ownerId: string) {
    return this.send<SgJoinRoomResponse>(
      this._client.post(`rooms/owner/${ownerId}/join`),
    )
  }*/

  /*
  joinRoomByRoomId(roomId: string) {
    return this.send<SgJoinRoomResponse>(
      this._client.post(`rooms/${roomId}/join`),
    )
  }
   */

  getStreamRoom(roomId: string) {
    return this.send<SgGetRoomResponse>(this._client.get(`streams/${roomId}`))
  }
  updateRoomParticipants(
    roomId: string,
    participants: {
      user: SgRoomParticipants
      display: SgRoomParticipants
    },
  ) {
    return this.send<SgGetRoomResponse>(
      this._client.put(`rooms/${roomId}`, participants),
    )
  }

  //joinChatRoom(chatRoomId: string) {
  //  return this.send(this._client.get(`chats/${chatRoomId}/join`))
  //}
  createChatToken(roomId: string) {
    return this.send(this._client.get(`chats/${roomId}/token`))
  }
  createBreakoutChatToken(roomId: string) {
    return this.send(this._client.get(`breakout/${roomId}/chats/token`))
  }
  listBreakoutChat(roomId: string, nextToken?: string) {
    return this.sendParams<{
      events: Array<{
        timestamp: number
        message: string
      }>
      nextForwardToken?: string
      nextBackwardToken?: string
    }>(
      `breakout/${roomId}/chats/history`,
      nextToken
        ? Params.fromObject({
            nextToken: encodeURIComponent(nextToken),
          })
        : undefined,
    )
  }
  listChat(roomId: string, nextToken?: string) {
    return this.sendParams<{
      events: Array<{
        timestamp: number
        message: string
      }>
      nextForwardToken?: string
      nextBackwardToken?: string
    }>(
      `chats/${roomId}/history`,
      nextToken
        ? Params.fromObject({
            nextToken: encodeURIComponent(nextToken),
          })
        : undefined,
    )
  }
  /*

  listChatToday(chatArn: string, nextToken?: string) {
    return this.sendParams<{
      events: Array<{
        timestamp: number
        message: string
      }>
      nextForwardToken?: string
      nextBackwardToken?: string
    }>(
      `chats/${chatArn}/today`,
      nextToken
        ? Params.fromObject({
            nextToken: encodeURIComponent(nextToken),
          })
        : undefined,
    )
  }
   */

  getStudentDailyCheck() {
    return this.send<StudentDailyCheck>(this._client.get('data/students/daily'))
  }
  getStudentDailyByDay(studentId: string, date: string) {
    return this.send<StudentDailyCheck>(
      this._client.get(`data/students/${studentId}/daily/${date}`),
    )
  }
  updateStudentDailyCheck(body: StudentDailyCheckReqBody) {
    return this.send<StudentDailyCheck>(
      this._client.post('data/students/daily', body),
    )
  }
  createBreakoutRoom(body: BreakoutRoomReqBody) {
    return this.send<BreakoutRoomReqBody>(this._client.post(`breakout`, body))
  }
  joinBreakoutRoom(id: string) {
    return this.send<JoinBreakoutRoomResponse>(
      this._client.get(`breakout/${id}`),
    )
  }
  getBreakoutRoom(roomId: string) {
    return this.send<{
      room: SgStreamRoom
      join: SgJoinRoomResponse
    }>(this._client.get(`breakout/${roomId}`))
  }
  endBreakoutRoom(
    roomId: string,
    body: {
      note: string
      isResolved: boolean
    },
  ) {
    return this.send<BreakoutRoom>(
      this._client.post(`breakout/${roomId}/end`, body),
    )
  }

  getUserInfo(userId: string) {
    return this.send<SgUserInfo | null>(
      this._client.get(`users/info/${userId}`),
    )
  }

  listUserStageRecord(userId: string, params?: Params) {
    return this.sendParams<PaginatedItems<ParticipantRecordingInfo>>(
      `records/users/${userId}`,
      params,
    )
  }
  listRoomStageRecord(roomId: string, params?: Params) {
    return this.sendParams<PaginatedItems<ParticipantRecordingInfo>>(
      `records/rooms/${roomId}`,
      params,
    )
  }
  listSessionStageRecord(sessionId: string, params?: Params) {
    return this.sendParams<PaginatedItems<ParticipantRecordingInfo>>(
      `records/sessions/${sessionId}`,
      params,
    )
  }
  /*listUserStageRecordDetail(userId: string, recordingKey: string) {
    return this.sendParams<
      PaginatedItems<{
        key: string
        lastModified: string
        eTag: string
        size: number
      }>
    >(`records/users/${userId}/${recordingKey}`).then((res) => {
      return {
        ...res,
        data: res.data.map((d) => {
          return {
            ...d,
            lastModified: new Date(d.lastModified),
          }
        }),
      }
    })
  }*/

  getStudentSchoolSetting() {
    return this.send<SchoolSettingsReqBody>(
      this._client.get(`data/students/school/settings`),
    )
  }

  getSchoolSetting(schoolId: string) {
    return this.send<SchoolSettingsReqBody>(
      this._client.get(`schools/${schoolId}/settings`),
    )
  }
  updateSchoolSetting(
    schoolId: string,
    body: {
      learnerFormId?: string
      seatFormNormalId?: string
      seatFormAuthorityId?: string
      subjectPermissions: string[]
      topicPermissions: string[]
    },
  ) {
    return this.send<{
      id: string
      learnerFormId: string
      seatFormId: string
      subjectPermissions: string[]
      topicPermissions: string[]
    }>(this._client.post(`schools/${schoolId}/settings`, body))
  }

  listBreakout(params?: Params, filters?: Record<string, unknown>) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<BreakoutRoomWithParticipants>>(
      this._client.post(`data/breakouts${paramStr}`, filters),
    )
  }
  getBreakout(id: Oid) {
    return this.send<BreakoutRoomWithParticipants>(
      this._client.get(`data/breakouts/${id.$oid}`),
    )
  }
  getUser(id: string) {
    return this.send<User>(this._client.get(`data/users/${id}`))
  }
  startBreakoutComposition(id: string) {
    return this.send<void>(this._client.get(`breakout/${id}/compose`))
  }
}

export function getSgService() {
  return SgService.getInstance()
}
