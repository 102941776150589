import { lazy, Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import {
  RecordChildrenPath,
  SharedChildrenPath,
} from '@/constants/shared_pages.ts'

// eslint-disable-next-line react-refresh/only-export-components
const UserRecordPage = lazy(
  () => import('@/pages/SharedPage/Record/UserRecordingPage.tsx'),
)
// eslint-disable-next-line react-refresh/only-export-components
const RoomRecordPage = lazy(
  () => import('@/pages/SharedPage/Record/RoomRecordingPage.tsx'),
)

export default {
  path: SharedChildrenPath.RECORD,
  element: (
    <Suspense fallback={<>loading record page</>}>
      <Outlet />
    </Suspense>
  ),
  children: [
    {
      path: `${RecordChildrenPath.ROOM}/:roomId`,
      element: <RoomRecordPage />,
    },
    {
      path: `${RecordChildrenPath.SESSION}/:sessionId`,
      element: <RoomRecordPage />,
    },
    {
      path: `${RecordChildrenPath.USER}/:userId`,
      element: <UserRecordPage />,
    },
  ],
}
