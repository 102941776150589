import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import DraggableAlertFab from '@/components/Buttons/Fab/DraggableAlertFab.tsx'
import DraggableChatFab from '@/components/Buttons/Fab/DraggableChatFab.tsx'
import { ChatDialog } from '@/components/Chat/ChatDialog.tsx'
import VideoDialog from '@/components/Video/VideoDialog.tsx'
import useAppContext from '@/hooks/useAppProvider.ts'
import useCheckRole from '@/hooks/useCheckRole.ts'
import { TeacherPageProvider } from '@/pages/TeacherPage/TeacherPageProvider.tsx'
import TeacherSidebar from '@/pages/TeacherPage/TeacherSidebar.tsx'

function PageImpl() {
  /*const { recordingFlag, setRecordingFlag } = useTeacherPage()
  const { joinResponse } = useLoaderData() as {
    joinResponse: PromiseLike<SgJoinRoomResponse>
  }*/

  return (
    <Suspense fallback={<div>loading teacher elements...</div>}>
      <Outlet />
      {/*
        <Await
        resolve={joinResponse}
        errorElement={<div>loading join error</div>}
      >
        <Outlet />
        <EnableScreenCaptureModal
          flags={recordingFlag}
          onChange={setRecordingFlag}
        />
        <EnableCameraModal flags={recordingFlag} onChange={setRecordingFlag} />
      </Await>
         */}
      <div className="flex-c justify-center gap-4 w-[var(--ed-sidebar-width)] px-8 fixed bottom-4 left-0">
        <DraggableAlertFab />
        <DraggableChatFab />
      </div>
    </Suspense>
  )
}

function PageImplStaff() {
  return (
    <Suspense>
      <Outlet />
      <div className="flex-c justify-center gap-4 w-[var(--ed-sidebar-width)] px-8 fixed bottom-4 left-0">
        <DraggableAlertFab />
        <DraggableChatFab />
      </div>
    </Suspense>
  )
}

export default function TeacherPage() {
  const [canView, user] = useCheckRole(['ROLE_EDCLASS_STAFF', 'ROLE_ROOT'])

  const { sidebar } = useAppContext()

  return canView ? (
    <TeacherPageProvider>
      <div className="p-4">
        <sidebar.In>
          <TeacherSidebar />
        </sidebar.In>
        <ChatDialog noSearch={false} noNav={false}>
          <VideoDialog>
            {user.teachingSubjects ? <PageImpl /> : <PageImplStaff />}
          </VideoDialog>
        </ChatDialog>
      </div>
    </TeacherPageProvider>
  ) : null
}
