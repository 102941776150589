//import { createEditor, LexicalEditor } from 'lexical'
import { useAudioAlert } from '@edclass/react-providers'
import PQueue from 'p-queue'
import {
  createContext,
  MutableRefObject,
  ReactNode,
  RefObject,
  useCallback,
  useRef,
  useState,
} from 'react'

import { AlertKeys } from '@/constants/constants.ts'
import useEffectOnce from '@/hooks/useEffectOnce.ts'
import { EventService } from '@/services/event.ts'

interface GlobalContextValue {
  //editor: LexicalEditor
  cache: MutableRefObject<Record<string, unknown>>
  builderMediaCache: string[]
  setBuilderMediaCache: SetState<string[]>
  builderPreviews: number[]
  setBuilderPreviews: SetState<number[]>
  removePreview: (id: number) => void
  addPreview: (id: number) => void
  logger: MutableRefObject<EventService>
  activeBucket: string
  setActiveBucket: SetState<string>
  queue: RefObject<PQueue>
  seatQuestions: SeatQuestion[]
  setSeatQuestions: SetState<SeatQuestion[]>
}

export const GlobalContext = createContext<GlobalContextValue | null>(null)
GlobalContext.displayName = 'GlobalContext'

const taskQueue = new PQueue({
  concurrency: 2,
  autoStart: true,
})

export default function GlobalProvider({ children }: { children: ReactNode }) {
  //const editor = createEditor(DEFAULT_EDITOR_CONFIG)
  const audioAlert = useAudioAlert()

  useEffectOnce(() => {
    audioAlert.add(AlertKeys.Student, '/alert.mp3')
    audioAlert.add(AlertKeys.CallIncoming, '/call-incoming.mp3')
    audioAlert.add(AlertKeys.CallIncoming, '/call-joined.mp3')
  })

  const cache = useRef<Record<string, unknown>>({})
  const [builderMediaCache, setBuilderMediaCache] = useState<string[]>([])
  const [builderPreviews, setBuilderPreviews] = useState<number[]>([])
  const [activeBucket, setActiveBucket] = useState(
    import.meta.env.VITE_APP_BUCKET_NAME,
  )

  const removePreview = useCallback(
    (id: number) => {
      setBuilderPreviews((prev) => prev.filter((p) => p !== id))
    },
    [setBuilderPreviews],
  )

  const addPreview = useCallback(
    (seconds: number) => {
      setBuilderPreviews((prev) => [...prev, seconds])
    },
    [setBuilderPreviews],
  )

  const queue = useRef(taskQueue)
  const logger = useRef(new EventService(taskQueue))
  const [seatQuestions, setSeatQuestions] = useState<SeatQuestion[]>([])

  return (
    <GlobalContext.Provider
      value={{
        //editor,
        cache,
        builderMediaCache,
        setBuilderMediaCache,
        builderPreviews,
        setBuilderPreviews,
        removePreview,
        addPreview,
        logger,
        activeBucket,
        setActiveBucket,
        queue,
        seatQuestions,
        setSeatQuestions,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}
