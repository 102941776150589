/*import { useCallback, useMemo } from 'react'

import useAuthContext from '@/hooks/useAuthProvider.ts'


export default function useCreateOrJoinChatRoom() {
  const { user: currentUser } = useAuthContext()

  return useMemo(() => {}, [])
}

export function useCreateOrJoinChatRoomCb() {
  const { user: currentUser } = useAuthContext()

  return useCallback(() => {}, [])
}
*/

import React, { useCallback, useRef } from 'react'

import { useChatDialog } from '@/components/Chat/ChatDialog.tsx'
import useAppContext from '@/hooks/useAppProvider.ts'
import useAuthContext from '@/hooks/useAuthProvider.ts'
import ChatRoom from '@/providers/ChatRoomProvider/ChatRoom.ts'

export function useCreateOrJoinChatRoomCb(): [
  (
    chatRoomRes: SgChatRoom | JoinBreakoutRoomResponse,
    isBreakout: boolean,
    connectCb?: (roomId: string) => void,
  ) => ChatRoom,
  React.MutableRefObject<ChatRoom | undefined>,
] {
  const { user: currentUser } = useAuthContext()
  const { setActiveRoom, addMessage, removeMessage, updateMap } =
    useChatDialog()
  const { sendSgMessage } = useAppContext()
  const chatRoomRef = useRef<ChatRoom>()

  return [
    useCallback(
      (
        chatRoomRes: SgChatRoom | JoinBreakoutRoomResponse,
        isBreakout,
        connectCb?: (roomId: string) => void,
      ) => {
        const roomId = !isBreakout
          ? (chatRoomRes as SgChatRoom)._id.$oid
          : (chatRoomRes as JoinBreakoutRoomResponse).roomId.$oid
        const chatArn = !isBreakout
          ? (chatRoomRes as SgChatRoom).chatArn
          : (chatRoomRes as JoinBreakoutRoomResponse).chatArn

        function onConnect() {
          setActiveRoom(roomId)
          connectCb?.(roomId)
        }

        const cr = new ChatRoom({
          isBreakout,
          roomId,
          chatArn,
          participants: isBreakout
            ? Object.keys(
                (chatRoomRes as JoinBreakoutRoomResponse).participants,
              )
            : Object.keys((chatRoomRes as SgChatRoom).participants),
          eventMap: {
            connect: onConnect,
          },
          wsSendMessage: sendSgMessage,
          updateState: updateMap,
          addMessage,
          removeMessage,
          currentUser: currentUser!,
          owner: !isBreakout
            ? (chatRoomRes as SgChatRoomWithOwner).owner
            : ((chatRoomRes as JoinBreakoutRoomResponse)
                .owner as unknown as SgParticipantAttributes),
        })

        chatRoomRef.current = cr
        return cr
      },
      [
        currentUser,
        updateMap,
        addMessage,
        removeMessage,
        setActiveRoom,
        chatRoomRef,
        sendSgMessage,
      ],
    ),
    chatRoomRef,
  ]
}
