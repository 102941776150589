import { AxiosResponse } from 'axios'

import { Http, HttpError } from '@/helpers/http.ts'
import { Params } from '@/services/params.ts'

export abstract class BaseService {
  protected abstract _client: Http

  errorCb?: (e: HttpError) => void
  protected getError(e: unknown, statusCode: number) {
    if (typeof e !== 'object' && e !== null) {
      return Promise.reject({
        message: e,
        statusCode,
      })
    }

    const ee = e as { message: string; errors: unknown; code: number }
    return Promise.reject({
      message: ee?.message,
      errors: ee?.errors,
      code: ee?.code,
      statusCode,
    })
  }

  setErrorCb(cb: (e: HttpError) => void) {
    this._client.setErrorCb(cb)
  }

  async sendParams<T>(ep: string, params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<T>(this._client.get(`${ep}${paramStr}`))
  }

  protected async send<T>(promise: Promise<AxiosResponse<T>>) {
    try {
      const res = await promise

      if (res.status >= 200) {
        const maybeWrapped = res.data as {
          success: boolean
          data: T
          message: string
        }
        return (maybeWrapped.success !== undefined ||
          maybeWrapped.message !== undefined) &&
          maybeWrapped.data
          ? maybeWrapped.data
          : (res.data as T)
      } else {
        return this.getError(res.data, res.status)
      }
    } catch (e) {
      return this.getError(e, 0)
    }
  }
}
