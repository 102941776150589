import {
  DndContext,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { ReactNode } from 'react'

export default function DraggableFab({
  setPosition,
  children,
}: {
  setPosition: SetState<{ x: number; y: number } | undefined>
  children: ReactNode
}) {
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      //distance: 2,
      delay: 250,
      tolerance: 4,
    },
  })

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      //distance: 2,
      delay: 250,
      tolerance: 4,
    },
  })
  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: {
      //distance: 2,
      delay: 250,
      tolerance: 4,
    },
  })

  const sensors = useSensors(mouseSensor, touchSensor, pointerSensor)
  return (
    <DndContext
      sensors={sensors}
      onDragEnd={(event) => {
        if (event.active) {
          const { delta } = event
          setPosition((prev) => {
            if (prev) {
              return {
                x: prev.x + delta.x,
                y: prev.y + delta.y,
              }
            } else {
              return {
                x: delta.x,
                y: delta.y,
              }
            }
          })
        }
      }}
    >
      {children}
    </DndContext>
  )
}
