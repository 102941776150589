// eslint-disable-next-line react-refresh/only-export-components
import {
  EDIvsParticipantInfo,
  EDIvsStageManagerContextValue,
} from '@edclass/ivs-providers'
import { createContext, ReactNode, useMemo, useState } from 'react'

import useContextOrThrow from '@/hooks/useContextOrThrow.ts'

type VideoDialogContextValue = {
  /*
  streams: Record<
    string,
    {
      display?: MediaStream
      user?: MediaStream
      info: EDIvsParticipantAttribute
    }
  >
   */
  userStreams: EDIvsParticipantInfo[]
  displayStreams: EDIvsParticipantInfo[]
  /*setStreams: SetState<
    Record<
      string,
      {
        display?: MediaStream
        user?: MediaStream
        info: EDIvsParticipantAttribute
      }
    >
  >
  addStream: (
    userId: string,
    kind: 'display' | 'user',
    stream: MediaStream,
  ) => void
  removeStream: (userId: string, kind: 'display' | 'user' | 'all') => void*/
  open: boolean
  setOpen: SetState<boolean>
  stageManager?: EDIvsStageManagerContextValue
  setStageManager: SetState<EDIvsStageManagerContextValue | undefined>
  /*getDisplayStreams: () => Record<
    string,
    {
      media: MediaStream
      info: EDIvsParticipantAttribute
    }
  >*/
}

const VideoDialogContext = createContext<VideoDialogContextValue | null>(null)
VideoDialogContext.displayName = 'VideoDialogContext'

// eslint-disable-next-line react-refresh/only-export-components
export function useVideoDialog() {
  return useContextOrThrow(VideoDialogContext)
}

export default function VideoDialog({ children }: { children?: ReactNode }) {
  /*
  const [streams, setStreams] = useState<
    Record<
      string,
      {
        display?: MediaStream
        user?: MediaStream
        info: EDIvsParticipantAttribute
      }
    >
  >({})

  const addStream = useCallback(
    (userId: string, kind: 'display' | 'user', stream: MediaStream) => {
      setStreams((prev) => {
        if (!prev[userId]?.[kind]) {
          const copy = { ...prev }
          copy[userId] = {
            ...(copy[userId] || {}),
            [kind]: stream,
          }
          return copy
        }
        return prev
      })
    },
    [],
  )

  const removeStream = useCallback(
    (userId: string, kind: 'display' | 'user' | 'all') => {
      setStreams((prev) => {
        if (kind === 'all') {
          if (prev[userId]) {
            delete prev[userId]
          }
          return prev
        } else {
          if (prev[userId]?.[kind]) {
            const copy = { ...prev }
            copy[userId] = {
              ...(copy[userId] || {}),
              [kind]: undefined,
            }
            delete copy[userId][kind]
            return copy
          }
        }

        return prev
      })
    },
    [],
  )*/

  const [open, setOpen] = useState(false)
  const [stageManager, setStageManager] =
    useState<EDIvsStageManagerContextValue>()

  const userStreams = useMemo(() => {
    return (
      stageManager?.user?.getParticipants({
        isLocal: false,
        isPublishing: true,
      }) || []
    )
  }, [stageManager?.user])

  const displayStreams = useMemo(() => {
    return (
      stageManager?.display?.getParticipants({
        isLocal: false,
        isPublishing: true,
      }) || []
    )
  }, [stageManager?.display])

  /*
  const streams = useMemo(() => {
    return (
      userStreams?.map((us) => {
        const display = displayStreams?.find((d) => d.userId === us.userId)
        return [
          us.attributes.id,
          {
            user: us.mediaStream,
            display: display?.mediaStream,
            info: us.attributes,
          },
        ]
      }) || []
    )
  }, [userStreams, displayStreams])*/

  return (
    <VideoDialogContext.Provider
      value={{
        stageManager,
        setStageManager,
        userStreams,
        displayStreams,
        //streams,
        //setStreams,
        //addStream,
        //removeStream,
        open,
        setOpen,
      }}
    >
      {children}
    </VideoDialogContext.Provider>
  )
}
