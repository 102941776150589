import {
  ApiEndpoint,
  parseResponseToTimetable,
  TimetableTimeOption,
} from '@edclass/fe-common'
import { AxiosRequestConfig } from 'axios'
import { uuidv7 } from 'uuidv7'

import { Http } from '@/helpers/http.ts'
import { DUMMY_GAMES } from '@/pages/AdminPage/AdminGamePage/dummy.tsx'
import { BaseService } from '@/services/base.ts'
import { Params } from '@/services/params.ts'

const BASE_URL = import.meta.env.VITE_API_URL
export const API_VERSION = 'v1'

class ApiService extends BaseService {
  private static instance: ApiService

  static getInstance(): ApiService {
    if (!ApiService.instance) {
      ApiService.instance = new ApiService()
    }
    return ApiService.instance
  }

  protected _client: Http

  constructor() {
    super()
    this._client = new Http({
      baseUrl: `${BASE_URL}`,
    })
  }

  async ping() {
    return this.send<void>(this._client.get(ApiEndpoint.Ping))
  }

  async listStaff(params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<User>>(
      this._client.get(`${ApiEndpoint.Staff}${paramStr}`),
    )
  }

  async getStaff(id: string) {
    return id
      ? this.send<User>(this._client.get(`${ApiEndpoint.Staff}/${id}`))
      : Promise.reject({
          message: 'Invalid Id',
        })
  }
  async addStaff(body: StaffReqBody): Promise<User> {
    return this.send<User>(this._client.post(ApiEndpoint.Staff, body))
  }

  async updateStaff(id: string, body: StaffReqBody) {
    return this.send<User>(this._client.put(`${ApiEndpoint.Staff}/${id}`, body))
  }

  async deleteStaff(id: string) {
    return this.send(this._client.delete(`${ApiEndpoint.Staff}/${id}`))
  }

  async listAcademicTerm(schoolId: string, params?: Params) {
    return this.sendParams<PaginatedItems<AcademicTerm>>(
      `${ApiEndpoint.School}/${schoolId}/${ApiEndpoint.AcademicTerm}`,
      params,
    )
  }
  async getAcademicTerm(schoolId: string, termId: string) {
    return this.send<AcademicTerm>(
      this._client.get(
        `${ApiEndpoint.School}/${schoolId}/${ApiEndpoint.AcademicTerm}/${termId}`,
      ),
    )
  }
  async addAcademicTerm(schoolId: string, body: AcademicTermReqBody) {
    return this.send<AcademicTerm>(
      this._client.post(
        `${ApiEndpoint.School}/${schoolId}/${ApiEndpoint.AcademicTerm}`,
        body,
      ),
    )
  }
  async updateAcademicTerm(schoolId: string, body: AcademicTermReqBody) {
    return this.send<AcademicTerm>(
      this._client.put(
        `${ApiEndpoint.School}/${schoolId}/${ApiEndpoint.AcademicTerm}/${body.id}`,
        body,
      ),
    )
  }
  async listHoliday(params?: Params) {
    return this.sendParams<PaginatedItems<AcademicHoliday>>('holidays', params)
  }
  async addHoliday(body: AcademicHolidayForm) {
    return this.send<AcademicHoliday>(
      this._client.post(`holidays`, body),
      //this._client.post(`school/${schoolId}/holidays`, body),
    )
  }
  async updateHoliday(body: AcademicHolidayForm) {
    return this.send<AcademicHoliday>(
      this._client.post(`holidays/${body.id}`, body),
    )
    //this._client.put(`school/${schoolId}/holidays/${body.id}`, body),
  }
  async delHoliday(id: string) {
    return this.send<AcademicHoliday>(
      this._client.delete(`holidays/${id}`),
      //this._client.delete(`school/${schoolId}/holidays/${id}`),
    )
  }

  async listSchoolHoliday(schoolId: string, params?: Params) {
    return this.sendParams<PaginatedItems<AcademicHoliday>>(
      `schools/${schoolId}/holidays`,
      params,
    )
  }
  async addSchoolHoliday(schoolId: string, body: AcademicHolidayForm) {
    return this.send<AcademicHoliday>(
      this._client.post(`school/${schoolId}/holidays`, body),
    )
  }
  async updateSchoolHoliday(schoolId: string, body: AcademicHolidayForm) {
    return this.send<AcademicHoliday>(
      this._client.put(`school/${schoolId}/holidays/${body.id}`, body),
    )
  }
  async delSchoolHoliday(schoolId: string, id: string) {
    return this.send<AcademicHoliday>(
      this._client.delete(`school/${schoolId}/holidays/${id}`),
    )
  }
  async listSchool(params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<School>>(
      this._client.get(`${ApiEndpoint.School}${paramStr}`),
    )
  }

  async listMatSchool(params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<School>>(
      this._client.get(`${ApiEndpoint.School}/mat${paramStr}`),
    )
  }
  async listSchoolStaff(schoolId: string, params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<User>>(
      this._client.get(
        `${ApiEndpoint.School}/${schoolId}/${ApiEndpoint.Staff}${paramStr}`,
      ),
    )
  }
  async listSchoolStudent(schoolId: string, params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<Student>>(
      this._client.get(
        `${ApiEndpoint.School}/${schoolId}/${ApiEndpoint.Student}${paramStr}`,
      ),
    )
  }
  async listSchoolSeatCandidate(schoolId: string, params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<Student>>(
      this._client.get(
        `${ApiEndpoint.School}/${schoolId}/${ApiEndpoint.Candidate}${paramStr}`,
      ),
    )
  }
  async listSchoolSeatNonCandidate(schoolId: string, params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<Student>>(
      this._client.get(
        `${ApiEndpoint.School}/${schoolId}/${ApiEndpoint.NonCandidate}${paramStr}`,
      ),
    )
  }
  async listSchoolBranch(schoolId: string, params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<School>>(
      this._client.get(
        `${ApiEndpoint.School}/${schoolId}/${ApiEndpoint.Branch}${paramStr}`,
      ),
    )
  }
  async listSchoolSubscription(schoolId: string, params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    /*return {
      data: DUMMY_SUBSCRIPTIONS,
      page: {
        perPage: 1,
        total: 1,
        totalPages: 1,
        current: 1,
      },
    } as PaginatedItems<Subscription>*/
    return this.send<PaginatedItems<Subscription>>(
      this._client.get(
        `${ApiEndpoint.School}/${schoolId}/${ApiEndpoint.Subscription}${paramStr}`,
      ),
    )
  }
  async listSchoolSeat(schoolId: string, params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<Seat>>(
      this._client.get(
        `${ApiEndpoint.School}/${schoolId}/${ApiEndpoint.Seat}${paramStr}`,
      ),
    )
  }
  async getSchoolSubscription(seatId: string) {
    //return DUMMY_SUBSCRIPTIONS[0] as Subscription
    return this.send<Subscription>(
      this._client.get(`${ApiEndpoint.Seat}/${seatId}`),
    )
  }
  async listStudent(params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<Student>>(
      this._client.get(`${ApiEndpoint.Student}${paramStr}`),
    )
  }
  async allocateSeatToBranch(seatId: string, branchId: string) {
    return this.send(
      this._client.post(
        `${ApiEndpoint.Seat}/${seatId}/${ApiEndpoint.Allocate}`,
        {
          school: branchId,
        },
      ),
    )
  }
  async listTimetable(seatId: string, params?: Params): Promise<Timetable> {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<TimetableItemResponse>>(
      this._client.get(
        `${ApiEndpoint.Seat}/${seatId}/${ApiEndpoint.Timetable}${paramStr}`,
      ),
    ).then((data) =>
      parseResponseToTimetable(data.data),
    ) as unknown as Timetable
  }

  async listTimetableByStudentId(
    studentId: string,
    params?: Params,
  ): Promise<Timetable> {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<TimetableItemResponse>>(
      this._client.get(
        `${ApiEndpoint.Student}/${studentId}/${ApiEndpoint.Timetable}${paramStr}`,
      ),
    ).then((data) =>
      parseResponseToTimetable(data.data),
    ) as unknown as Timetable
  }
  async listTimetableBySchool(schoolId: string, params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<
      Record<WeekDay, Record<TimetableTimeOption, Array<TimetableItem>>>
    >(
      this._client.get(
        `${ApiEndpoint.School}/${schoolId}/${ApiEndpoint.Timetable}${paramStr}`,
      ),
    )
  }
  async downloadTimetableBySchool(schoolId: string, params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<
      Record<WeekDay, Record<TimetableTimeOption, Array<TimetableItem>>>
    >(
      this._client.get(
        `${ApiEndpoint.School}/${schoolId}/${ApiEndpoint.Timetable}/dl${paramStr}`,
      ),
    )
  }

  async addTimetable({ seatId, items }: TimetableReqBody) {
    return this.send<void>(
      this._client.post(
        `${ApiEndpoint.Seat}/${seatId}/${ApiEndpoint.Timetable}`,
        items,
      ),
    )
  }
  async getTimetable(timetableId: string): Promise<Timetable> {
    return this.send<TimetableResponse>(
      this._client.get(`${ApiEndpoint.Timetable}/${timetableId}`),
    ).then((data) => parseResponseToTimetable(data)) as unknown as Timetable
  }
  async getStudentTimetable(studentId: string): Promise<Timetable> {
    return this.send<PaginatedItems<TimetableItemResponse>>(
      this._client.get(
        `${ApiEndpoint.Student}/${studentId}/${ApiEndpoint.Timetable}`,
      ),
    ).then((data) =>
      parseResponseToTimetable(data.data),
    ) as unknown as Timetable
  }
  async addSchoolStudent(schoolId: string, body: StudentReqBody) {
    return this.send<Student>(
      this._client.post(
        `${ApiEndpoint.School}/${schoolId}/${ApiEndpoint.Student}`,
        body,
      ),
    )
  }

  async updateSchoolStudent(studentId: string, body: StudentReqBody) {
    return this.send<Student>(
      this._client.put(`${ApiEndpoint.Student}/${studentId}`, body),
    )
  }
  async addStudentSeatQuestionAnswer(
    studentId: string,
    answer: SeatQuestionAnswer,
  ) {
    return this.send<Student>(
      this._client.post(
        `${ApiEndpoint.Student}/${studentId}/${ApiEndpoint.Questionnaire}`,
        {
          questionnaire: answer,
        },
      ),
    )
  }
  async listStudentQuestion(initialParams?: Params) {
    return this.sendParams<PaginatedItems<StudentQuestion>>(
      `${ApiEndpoint.Student}/${ApiEndpoint.Question}`,
      initialParams,
    )
  }
  async addStudentQuestion(body: StudentQuestionReqBody) {
    return this.send<StudentQuestion>(
      this._client.post(`${ApiEndpoint.Student}/${ApiEndpoint.Question}`, body),
    )
  }
  async updateStudentQuestion(body: StudentQuestionReqBody) {
    return this.send<StudentQuestion>(
      this._client.put(
        `${ApiEndpoint.Student}/${ApiEndpoint.Question}/${body.id}`,
        body,
      ),
    )
  }
  async deleteStudentQuestion(id: string) {
    return this.send<void>(
      this._client.delete(
        `${ApiEndpoint.Student}/${ApiEndpoint.Question}/${id}`,
      ),
    )
  }
  async addStudentAsCandidate(
    schoolId: string,
    studentId: string,
    questionnaireId: string,
  ) {
    return this.send<{
      student: Student
    }>(
      this._client.post(
        `${ApiEndpoint.School}/${schoolId}/${ApiEndpoint.Candidate}`,
        {
          student: studentId,
          questionnaire: questionnaireId,
        },
      ),
    )
  }
  async getStudent(studentId: string) {
    return this.send<Student>(
      this._client.get(`${ApiEndpoint.Student}/${studentId}`),
    )
  }
  async deleteStudent(studentId: string) {
    return this.send<void>(
      this._client.delete(`${ApiEndpoint.Student}/${studentId}`),
    )
  }
  async addSchoolSubscription(
    schoolId: string,
    subscription: SubscriptionReqBody,
  ) {
    return this.send<Subscription>(
      this._client.post(
        `${ApiEndpoint.School}/${schoolId}/${ApiEndpoint.Subscription}`,
        subscription,
      ),
    )
  }
  async deleteSubscription(subscriptionId: string) {
    return this.send<void>(
      this._client.delete(`${ApiEndpoint.Subscription}/${subscriptionId}`),
    )
  }
  async getSchool(id: string) {
    return id
      ? this.send<School>(this._client.get(`${ApiEndpoint.School}/${id}`))
      : Promise.reject({
          message: 'Invalid Id',
        })
  }
  async addSchool(body: SchoolReqBody) {
    return this.send<School>(this._client.post(ApiEndpoint.School, body))
  }

  async updateSchool(id: string, body: Partial<SchoolReqBody>) {
    return this.send<School>(
      this._client.put(`${ApiEndpoint.School}/${id}`, body),
    )
  }
  async updateSchoolPermissions(id: string, body: SubjectTopicPermission) {
    return this.send(
      this._client.post(`${ApiEndpoint.School}/${id}/permissions`, body),
    )
  }

  async updateSchoolStatus(id: string, active: boolean) {
    return this.send<void>(
      this._client.patch(`${ApiEndpoint.School}/${id}/status`, {
        active,
      }),
    )
  }

  async deleteSchool(id: string) {
    return this.send<void>(this._client.delete(`${ApiEndpoint.School}/${id}`))
  }

  async listExBoard(params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<ExBoard>>(
      this._client.get(`${ApiEndpoint.ExBoard}${paramStr}`),
    )
  }
  async listExBoardArchive(params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<ExBoard>>(
      this._client.get(`${ApiEndpoint.ExBoard}/archived${paramStr}`),
    )
  }
  async getExBoard(id: string) {
    return this.send<ExBoard>(this._client.get(`${ApiEndpoint.ExBoard}/${id}`))
  }
  async addExBoard(body: ExBoardReqBody) {
    return this.send<ExBoard>(this._client.post(ApiEndpoint.ExBoard, body))
  }
  async updateExBoard(id: string, body: ExBoardReqBody) {
    return this.send<ExBoard>(
      this._client.put(`${ApiEndpoint.ExBoard}/${id}`, body),
    )
  }
  async deleteExBoard(id: string) {
    return this.send<void>(this._client.delete(`${ApiEndpoint.ExBoard}/${id}`))
  }
  async restoreExBoard(id: string) {
    return this.send<void>(
      this._client.post(`${ApiEndpoint.ExBoard}/${id}/restore`),
    )
  }
  async listExBoardGrading(exId: string, params?: Params) {
    return this.sendParams<PaginatedItems<ExBoardGradingBySubject>>(
      `${ApiEndpoint.ExBoard}/${exId}/grading`,
      params,
    )
  }
  async getExBoardGrading(exId: string, gradeId: string) {
    return this.send<ExBoardGradingBySubject>(
      this._client.get(`${ApiEndpoint.ExBoard}/${exId}/grading/${gradeId}`),
    )
  }
  async addExBoardGrading(exId: string, body: ExBoardGradingBySubjectReqBody) {
    return this.send<ExBoardGradingBySubject>(
      this._client.post(`${ApiEndpoint.ExBoard}/${exId}/grading`, body),
    )
  }
  async updateExBoardGrading(
    exId: string,
    gradeId: string,
    body: ExBoardGradingBySubjectReqBody,
  ) {
    return this.send<ExBoardGradingBySubject>(
      this._client.put(
        `${ApiEndpoint.ExBoard}/${exId}/grading/${gradeId}`,
        body,
      ),
    )
  }
  async removeExBoardGrading(exId: string, gradeId: string) {
    return this.send<ExBoardGradingBySubject>(
      this._client.delete(`${ApiEndpoint.ExBoard}/${exId}/grading/${gradeId}`),
    )
  }
  async listExBoardObjective(exId: string, params?: Params) {
    return this.sendParams<PaginatedItems<ExBoardObjectiveBySubject>>(
      `${ApiEndpoint.ExBoard}/${exId}/objectives`,
      params,
    )
  }
  async listExBoardObjectiveSubParts(exId: string, params?: Params) {
    return this.sendParams<PaginatedItems<ExBoardObjectiveSubPartDetail>>(
      `${ApiEndpoint.ExBoard}/${exId}/sub-parts`,
      params,
    ).then((res) => {
      return {
        data: res.data,
        page: {
          total: res.data.length,
          totalPages: res.data.length,
          current: 1,
          perPage: res.data.length,
        },
      }
    })
  }
  async getExBoardObjective(exId: string, gradeId: string) {
    return this.send<ExBoardObjectiveBySubject>(
      this._client.get(`${ApiEndpoint.ExBoard}/${exId}/objectives/${gradeId}`),
    )
  }
  async addExBoardObjective(
    exId: string,
    body: ExBoardObjectiveBySubjectReqBody,
  ) {
    return this.send<ExBoardObjectiveBySubject>(
      this._client.post(`${ApiEndpoint.ExBoard}/${exId}/objectives`, body),
    )
  }
  async updateExBoardObjective(
    exId: string,
    objId: string,
    body: ExBoardObjectiveBySubjectReqBody,
  ) {
    return this.send<ExBoardObjectiveBySubject>(
      this._client.put(
        `${ApiEndpoint.ExBoard}/${exId}/objectives/${objId}`,
        body,
      ),
    )
  }
  async removeExBoardObjective(exId: string, gradeId: string) {
    return this.send<ExBoardObjectiveBySubject>(
      this._client.delete(
        `${ApiEndpoint.ExBoard}/${exId}/objectives/${gradeId}`,
      ),
    )
  }

  async listSubject(params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<Subject>>(
      this._client.get(`${ApiEndpoint.Subject}${paramStr}`),
    )
  }
  async listSubjectArchive(params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<Subject>>(
      this._client.get(`${ApiEndpoint.Subject}/archived${paramStr}`),
    )
  }
  async getSubject(id: string) {
    return this.send<Subject>(this._client.get(`${ApiEndpoint.Subject}/${id}`))
  }
  async addSubject(body: SubjectReqBody) {
    return this.send<Subject>(this._client.post(ApiEndpoint.Subject, body))
  }

  async updateSubject(id: string, body: SubjectReqBody) {
    return this.send<Subject>(
      this._client.put(`${ApiEndpoint.Subject}/${id}`, body),
    )
  }

  async deleteSubject(id: string) {
    return this.send<void>(this._client.delete(`${ApiEndpoint.Subject}/${id}`))
  }
  async restoreSubject(id: string) {
    return this.send<void>(
      this._client.post(`${ApiEndpoint.Subject}/${id}/restore`),
    )
  }

  async listTopic(params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<Topic>>(
      this._client.get(`${ApiEndpoint.Topic}${paramStr}`),
    )
  }
  async listTopicArchive(params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<Topic>>(
      this._client.get(`${ApiEndpoint.Topic}/archived${paramStr}`),
    )
  }
  async listTopicBySubject(subjectId: string, params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<Topic>>(
      this._client.get(`${ApiEndpoint.Subject}/${subjectId}/topics${paramStr}`),
    )
  }
  async getTopic(id: string) {
    return this.send<Topic>(this._client.get(`${ApiEndpoint.Topic}/${id}`))
  }
  async addTopic(body: TopicReqBody) {
    return this.send<Topic>(this._client.post(ApiEndpoint.Topic, body))
  }
  async updateTopic(id: string, body: TopicReqBody) {
    return this.send<Topic>(
      this._client.put(`${ApiEndpoint.Topic}/${id}`, body),
    )
  }
  async deleteTopic(id: string) {
    return this.send<void>(this._client.delete(`${ApiEndpoint.Topic}/${id}`))
  }
  async restoreTopic(id: string) {
    return this.send<void>(
      this._client.post(`${ApiEndpoint.Topic}/${id}/restore`),
    )
  }
  async listSkillTags(params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<SkillTag>>(
      this._client.get(
        `${ApiEndpoint.Skill}/${ApiEndpoint.SkillTag}${paramStr}`,
      ),
    )
  }
  async getSkillTag(id: string) {
    return this.send<SkillTag>(
      this._client.get(`${ApiEndpoint.Skill}/${ApiEndpoint.SkillTag}/${id}`),
    )
  }
  async addSkillTag(body: SkillTagReqBody) {
    return this.send<SkillTag>(
      this._client.post(`${ApiEndpoint.Skill}/${ApiEndpoint.SkillTag}`, body),
    )
  }
  async updateSkillTag(id: string, body: SkillTagReqBody) {
    return this.send<SkillTag>(
      this._client.put(
        `${ApiEndpoint.Skill}/${ApiEndpoint.SkillTag}/${id}`,
        body,
      ),
    )
  }
  async deleteSkillTag(id: string) {
    return this.send<void>(
      this._client.delete(`${ApiEndpoint.Skill}/${ApiEndpoint.SkillTag}/${id}`),
    )
  }
  async restoreSkillTag(id: string) {
    return this.send<void>(
      this._client.post(
        `${ApiEndpoint.Skill}/${ApiEndpoint.SkillTag}/${id}/restore`,
      ),
    )
  }

  async listSubjectSkill(params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<SubjectSpecificSkill>>(
      this._client.get(`${ApiEndpoint.SubjectSkill}${paramStr}`),
    )
  }
  async getSubjectSkill(id: string) {
    return this.send<SubjectSpecificSkill>(
      this._client.get(`${ApiEndpoint.SubjectSkill}/${id}`),
    )
  }
  async addSubjectSkill(body: SubjectSpecificSkillReqBody) {
    return this.send<SubjectSpecificSkill>(
      this._client.post(`${ApiEndpoint.SubjectSkill}`, {
        ...body,
        level: body.level ? Number(body.level) : body.level,
      }),
    )
  }
  async updateSubjectSkill(id: string, body: SubjectSpecificSkillReqBody) {
    return this.send<SubjectSpecificSkill>(
      this._client.put(`${ApiEndpoint.SubjectSkill}/${id}`, {
        ...body,
        level: body.level ? Number(body.level) : body.level,
      }),
    )
  }
  async deleteSubjectSkill(id: string) {
    return this.send<void>(
      this._client.delete(`${ApiEndpoint.SubjectSkill}/${id}`),
    )
  }
  async restoreSubjectSkill(id: string) {
    return this.send<void>(
      this._client.post(`${ApiEndpoint.SubjectSkill}/${id}/restore`),
    )
  }
  async listKeySkill(params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<KeySkill>>(
      this._client.get(`${ApiEndpoint.KeySkill}${paramStr}`),
    )
  }
  async getKeySkill(id: string) {
    return this.send<KeySkill>(
      this._client.get(`${ApiEndpoint.KeySkill}/${id}`),
    )
  }
  async addKeySkill(body: KeySkillReqBody) {
    return this.send<KeySkill>(
      this._client.post(`${ApiEndpoint.KeySkill}`, body),
    )
  }
  async updateKeySkill(id: string, body: KeySkillReqBody) {
    return this.send<KeySkill>(
      this._client.put(`${ApiEndpoint.KeySkill}/${id}`, body),
    )
  }
  async deleteKeySkill(id: string) {
    return this.send<void>(this._client.delete(`${ApiEndpoint.KeySkill}/${id}`))
  }
  async restoreKeySkill(id: string) {
    return this.send<void>(
      this._client.post(`${ApiEndpoint.KeySkill}/${id}/restore`),
    )
  }
  async listSkill(params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<Skill>>(
      this._client.get(`${ApiEndpoint.Skill}${paramStr}`),
    )
  }
  async listSkillArchive(params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<Skill>>(
      this._client.get(`${ApiEndpoint.Skill}/archived${paramStr}`),
    )
  }
  async getSkill(id: string) {
    return this.send<Skill>(this._client.get(`${ApiEndpoint.Skill}/${id}`))
  }
  async addSkill(body: SkillReqBody) {
    return this.send<Skill>(
      this._client.post(ApiEndpoint.Skill, {
        ...body,
        level: body.level ? Number(body.level) : body.level,
      }),
    )
  }
  async updateSkill(id: string, body: SkillReqBody) {
    return this.send<Skill>(
      this._client.put(`${ApiEndpoint.Skill}/${id}`, {
        ...body,
        level: body.level ? Number(body.level) : body.level,
      }),
    )
  }
  async deleteSkill(id: string) {
    return this.send<void>(this._client.delete(`${ApiEndpoint.Skill}/${id}`))
  }
  async restoreSkill(id: string) {
    return this.send<void>(
      this._client.post(`${ApiEndpoint.Skill}/${id}/restore`),
    )
  }
  async wondeGetSchool(id: string) {
    return this.send<WondeSchool>(
      this._client.get(`${ApiEndpoint.Wonde}/${id}`),
    )
  }

  async listPathway(params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<Pathway>>(
      this._client.get(`${ApiEndpoint.Pathway}${paramStr}`),
    )
  }
  async listPathwayArchive(params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<Pathway>>(
      this._client.get(`${ApiEndpoint.Pathway}/archived${paramStr}`),
    )
  }
  async listPathwayRevision(pathwayId?: string, params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<PathwayRevision>>(
      this._client.get(
        `${ApiEndpoint.Pathway}/${pathwayId}/${ApiEndpoint.Revision}${paramStr}`,
      ),
    )
  }
  async getPathway(id: string) {
    return this.send<Pathway>(this._client.get(`${ApiEndpoint.Pathway}/${id}`))
  }
  async listStudentPathway(studentId: string, params?: Params) {
    return this.sendParams<PaginatedItems<Pathway>>(
      `${ApiEndpoint.Student}/${studentId}/${ApiEndpoint.Pathway}`,
      params,
    )
  }
  async getStudentPathway(studentId: string, pathwayId: string) {
    return this.send<Pathway>(
      this._client.get(
        `${ApiEndpoint.Student}/${studentId}/${ApiEndpoint.Pathway}/${pathwayId}`,
      ),
    )
  }

  async getPathwayRevision(revision: string) {
    return this.send<Pathway>(
      this._client.get(
        `${ApiEndpoint.Pathway}/${ApiEndpoint.Revision}/${revision}`,
      ),
    )
  }

  async getPathwayDraft(id: string) {
    return this.send<PathwayRevision>(
      this._client.get(`${ApiEndpoint.Pathway}/${id}/draft`),
    )
  }

  async addPathway(body: PathwayPropertyReqBody) {
    return this.send<Pathway>(this._client.post(ApiEndpoint.Pathway, body))
  }

  async addPathwayRevision(id: string) {
    return this.send<PathwayRevision>(
      this._client.post(`${ApiEndpoint.Pathway}/${id}/${ApiEndpoint.Revision}`),
    )
  }

  async updatePathwayDraft(
    id: string,
    body: PathwayRevisionReqBody,
    isSubmit: boolean,
  ) {
    return this.send<PathwayRevision>(
      this._client.put(`${ApiEndpoint.Pathway}/${ApiEndpoint.Revision}/${id}`, {
        ...body,
        submit: isSubmit,
      }),
    )
  }
  async submitPathway(revisionId: string, action: 'reject' | 'approve') {
    return this.send<Omit<PathwayRevision, 'lessons'>>(
      this._client.post(
        `${ApiEndpoint.Pathway}/${ApiEndpoint.Revision}/${revisionId}:${action}`,
      ),
    )
  }

  async updatePathway(body: PathwayPropertyReqBody) {
    return this.send(
      this._client.put(`${ApiEndpoint.Pathway}/${body.id}`, body),
    )
  }

  async clonePathway(body: PathwayPropertyReqBody) {
    return this.send<Pathway>(
      this._client.post(`${ApiEndpoint.Pathway}/${body.id}/clone`, body),
    )
  }
  async deletePathway(id: string) {
    return this.send<void>(this._client.delete(`${ApiEndpoint.Pathway}/${id}`))
  }
  async restorePathway(id: string) {
    return this.send<void>(
      this._client.post(`${ApiEndpoint.Pathway}/${id}/restore`),
    )
  }

  async listLesson(params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<LessonItem>>(
      this._client.get(`${ApiEndpoint.Lesson}${paramStr}`),
    )
  }
  async listLessonArchive(params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<LessonItem>>(
      this._client.get(`${ApiEndpoint.Lesson}/archived${paramStr}`),
    )
  }
  async listLessonByTopic(_topicId: string, params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<LessonItem>>(
      this._client.get(`${ApiEndpoint.Lesson}${paramStr}`),
    )
  }
  async listLessonRevision(lessonId: string, params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<LessonItemRevision>>(
      this._client.get(
        `${ApiEndpoint.Lesson}/${lessonId}/${ApiEndpoint.Revision}${paramStr}`,
      ),
    )
  }
  async editLessonDraft(lessonId: string) {
    return this.send<{
      lesson: Omit<LessonItem, 'revision'>
      active: LessonItemRevision | null
      draft: LessonItemRevision | null
    }>(this._client.get(`${ApiEndpoint.Lesson}/${lessonId}/draft`))
  }

  /// LESSON WORKFLOW
  async cloneRevision(lessonId: string) {
    return this.send<LessonItemRevision>(
      this._client.post(`${ApiEndpoint.Lesson}/${lessonId}/clone`, {
        revisionDir: `rev-${uuidv7()}`,
      }),
    )
  }
  async submitLessonDraft(lessonId: string, changeType: RevisionChangeType) {
    return this.send<LessonItem>(
      this._client.post(`${ApiEndpoint.Lesson}/${lessonId}/submit`, {
        changeType,
      }),
    )
  }
  async startLessonReviewDraft(lessonId: string) {
    return this.send<{
      active: LessonItem | null
      draft: LessonItem | null
    }>(this._client.post(`${ApiEndpoint.Lesson}/${lessonId}/start-review`))
  }

  async rejectLessonInDraft(lessonId: string, feedback?: string | null) {
    return this.send<LessonItem>(
      this._client.post(`${ApiEndpoint.Lesson}/${lessonId}/reject-draft`, {
        feedback: feedback || null,
      }),
    )
  }
  async acceptLessonInDraft(
    lessonId: string,
    body: {
      changeType: RevisionChangeType
      feedback?: string | null
    },
  ) {
    return this.send<LessonItem>(
      this._client.post(`${ApiEndpoint.Lesson}/${lessonId}/accept-draft`, {
        changeType: body.changeType,
        feedback: body.feedback || null,
      }),
    )
  }
  async approveReviewedLesson(lessonId: string, feedback?: string | null) {
    return this.send<LessonItem>(
      this._client.post(`${ApiEndpoint.Lesson}/${lessonId}/approve`, {
        feedback: feedback || null,
      }),
    )
  }
  async rejectReviewedLesson(lessonId: string, feedback?: string | null) {
    return this.send<LessonItem>(
      this._client.post(`${ApiEndpoint.Lesson}/${lessonId}/reject`, {
        feedback: feedback || null,
      }),
    )
  }
  async listCommentByRevision(revisionId: string) {
    return this.send<{
      data: {
        // slideId
        id: string
        comments: SlideComment[]
      }[]
    }>(
      this._client.get(
        `${ApiEndpoint.Revision}/${revisionId}/${ApiEndpoint.Comment}`,
      ),
    )
  }
  async addComment(body: SlideCommentReqBody) {
    return this.send<SlideComment>(
      this._client.post(
        `${ApiEndpoint.Slide}/${body.slideId}/${ApiEndpoint.Comment}`,
        body,
      ),
    )
  }
  async updateComment(body: SlideCommentReqBody) {
    if (!body.id) {
      return Promise.reject('need id in comment body')
    }
    return this.send<SlideComment>(
      this._client.put(`${ApiEndpoint.Comment}/${body.id}`, body),
    )
  }
  async resolveComment(commentId: string) {
    return this.send<boolean>(
      this._client.put(`${ApiEndpoint.Comment}/${commentId}/resolved`),
    )
  }
  async deleteComment(commentId: string) {
    return this.send<void>(
      this._client.delete(`${ApiEndpoint.Comment}/${commentId}`),
    )
  }
  async addReply(commentId: string, body: SlideCommentReply) {
    return this.send<SlideCommentReply>(
      this._client.post(
        `${ApiEndpoint.Comment}/${commentId}/${ApiEndpoint.Reply}`,
        body,
      ),
    )
  }
  async editReply(body: SlideCommentReply) {
    if (!body.id) {
      return Promise.reject('must have id in the body')
    }
    return this.send<SlideCommentReply>(
      this._client.put(`${ApiEndpoint.Reply}/${body.id}`, body),
    )
  }
  async deleteReply(replyId: string) {
    return this.send<void>(
      this._client.delete(`${ApiEndpoint.Reply}/${replyId}`),
    )
  }
  /// END LESSON WORKFLOW
  async getLesson(id: string) {
    return this.send<LessonItem>(
      this._client.get(`${ApiEndpoint.Lesson}/${id}`),
    )
  }
  async getLessonSlideCount(id: string) {
    return this.send<number>(
      this._client.get(`${ApiEndpoint.Lesson}/${id}/slide-count`),
    )
  }
  async getLessonRevision(revId: string) {
    return this.send<LessonItemRevision>(
      this._client.get(
        `${ApiEndpoint.Lesson}/${ApiEndpoint.Revision}/${revId}`,
      ),
    )
  }

  async getActiveLesson(id: string) {
    return this.send<LessonItemRevision>(
      this._client.get(`${ApiEndpoint.Lesson}/${id}/active`),
    )
  }

  async addLesson(body: LessonItemAddReqBody) {
    return this.send<LessonItem>(this._client.post(ApiEndpoint.Lesson, body))
  }
  // this is updating lesson property
  async updateLessonProperty(id: string, body: LessonItemEditReqBody) {
    return this.send<LessonItem>(
      this._client.put(`${ApiEndpoint.Lesson}/${id}`, body),
    )
  }
  async restoreLesson(id: string) {
    return this.send<void>(
      this._client.post(`${ApiEndpoint.Lesson}/${id}/restore`),
    )
  }
  async deleteLesson(id: string) {
    return this.send<void>(this._client.delete(`${ApiEndpoint.Lesson}/${id}`))
  }

  async listLessonSlide(revisionId: string) {
    return this.send<{
      data: LessonSlide[]
    }>(
      this._client.get(
        `${ApiEndpoint.Revision}/${revisionId}/${ApiEndpoint.Slide}`,
      ),
    )
  }
  async addSlide(
    revisionId: string,
    body: {
      data: LessonSlideReqBody
      slidesOrder: {
        isAfter: boolean
        index: number
      }
    },
  ) {
    return this.send<LessonSlide>(
      this._client.post(
        `${ApiEndpoint.Revision}/${revisionId}/${ApiEndpoint.Slide}`,
        body,
      ),
    )
  }
  async orderSlide(revId: string, ids: string[]) {
    return this.send(
      this._client.post(
        `${ApiEndpoint.Lesson}/${ApiEndpoint.Revision}/${revId}/order`,
        ids,
      ),
    )
  }
  async updateSlide(body: LessonSlideReqBody) {
    return this.send<LessonSlide>(
      this._client.put(`${ApiEndpoint.Slide}/${body.id}`, body),
    )
  }
  async deleteSlide(id: string) {
    return this.send<void>(this._client.delete(`${ApiEndpoint.Slide}/${id}`))
  }
  /// TRACK
  async listTrack(slideId: string, params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<Track[]>(
      this._client.get(
        `${ApiEndpoint.Slide}/${slideId}/${ApiEndpoint.Track}${paramStr}`,
      ),
    )
  }
  async addTrack<T extends Track = Track>(slideId: string, body: T) {
    return this.send<T>(
      this._client.post(
        `${ApiEndpoint.Slide}/${slideId}/${ApiEndpoint.Track}`,
        body,
      ),
    )
  }
  async updateTrack<T extends Track = Track>(body: T) {
    return this.send<T>(
      this._client.put(`${ApiEndpoint.Track}/${body.id}`, body),
    )
  }
  async deleteTrack(id: string) {
    return this.send<void>(this._client.delete(`${ApiEndpoint.Track}/${id}`))
  }
  /// QUESTIONS
  async listQuestion(params?: Params) {
    if (params) {
      params.replace('kind', 'type')
    }
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<LessonTrackItem>>(
      this._client.get(`${ApiEndpoint.QBank}${paramStr}`),
    )
  }
  async listQuestionArchive(params?: Params) {
    if (params) {
      params.replace('kind', 'type')
    }
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<LessonTrackItem>>(
      this._client.get(`${ApiEndpoint.QBank}/archived${paramStr}`),
    )
  }
  async getQuestion(id: string) {
    return this.send<LessonTrackItem>(
      this._client.get(`${ApiEndpoint.QBank}/${id}`),
    )
  }
  async getQuestionBulk(ids: string[]) {
    return this.send<LessonTrackItem[]>(
      this._client.post(`${ApiEndpoint.QBank}/batch`, {
        ids,
      }),
    )
  }
  async addQuestion(body: LessonTrackReqBody) {
    return this.send<LessonTrackItem>(
      this._client.post(ApiEndpoint.QBank, body),
    )
  }
  async updateQuestion(body: LessonTrackReqBody) {
    return this.send<LessonTrackItem>(
      this._client.put(`${ApiEndpoint.QBank}/${body.id}`, body),
    )
  }
  async deleteQuestion(id: string) {
    return this.send<void>(this._client.delete(`${ApiEndpoint.QBank}/${id}`))
  }
  async restoreQuestion(id: string) {
    return this.send<void>(
      this._client.post(`${ApiEndpoint.QBank}/${id}/restore`),
    )
  }
  async listDAQ(params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<DAQ>>(
      this._client.get(`${ApiEndpoint.DAQ}${paramStr}`),
    )
  }
  async getDAQ(id: string) {
    return this.send<DAQReqBody>(
      this._client.get(`${ApiEndpoint.DAQ}/${id}`),
    ).then(async (res) => {
      const nodes = await Promise.all(
        res.nodes.map(async (node) => {
          const p = []
          if (typeof node.data.values.topic === 'string') {
            p.push(this.getTopic(node.data.values.topic))
          } else {
            p.push(Promise.resolve(undefined))
          }
          if (
            node.data.values.kind === 'lesson' &&
            typeof node.data.values.lesson === 'string'
          ) {
            p.push(this.getLesson(node.data.values.lesson))
          } else {
            p.push(Promise.resolve(undefined))
          }
          if (
            node.data.values.kind !== 'lesson' &&
            typeof node.data.values.question === 'string'
          ) {
            p.push(this.getQuestion(node.data.values.question))
          } else {
            p.push(Promise.resolve(undefined))
          }

          const [topic, lesson, question] = await Promise.all(p)

          return {
            ...node,
            data: {
              ...node.data,
              values: {
                ...node.data.values,
                lesson,
                topic,
                question,
              },
            },
          }
        }),
      )

      return {
        ...res,
        nodes: nodes,
      } as DAQ
    })
  }
  async addDAQ(body: DAQReqBody) {
    return this.send<DAQ>(this._client.post(`${ApiEndpoint.DAQ}`, body))
  }
  async updateDAQ(id: string, body: DAQReqBody) {
    return this.send<DAQ>(this._client.put(`${ApiEndpoint.DAQ}/${id}`, body))
  }
  async deleteDAQ(id: string) {
    return this.send<void>(this._client.delete(`${ApiEndpoint.DAQ}/${id}`))
  }
  async restoreDAQ(id: string) {
    return this.send<void>(
      this._client.post(`${ApiEndpoint.DAQ}/${id}/restore`),
    )
  }
  /// DND ITEMS
  async listDndItem(params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<DndItem>>(
      this._client.get(`${ApiEndpoint.Dnd}${paramStr}`),
    )
  }
  async listDndItemArchive(params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<DndItem>>(
      this._client.get(`${ApiEndpoint.Dnd}/archived${paramStr}`),
    )
  }
  async getDnd(id: string) {
    return this.send<DndItem>(this._client.get(`${ApiEndpoint.Dnd}/${id}`))
  }
  async addDnd(body: DndItemReqBody) {
    console.log('add dnd', body, ApiEndpoint.Dnd)
    return this.send<DndItem>(this._client.post(ApiEndpoint.Dnd, body))
  }
  async updateDnd(body: DndItemReqBody) {
    return this.send<DndItem>(
      this._client.put(`${ApiEndpoint.Dnd}/${body.id}`, body),
    )
  }
  async deleteDnd(id: string) {
    return this.send<void>(this._client.delete(`${ApiEndpoint.Dnd}/${id}`))
  }
  async restoreDnd(id: string) {
    return this.send<void>(
      this._client.post(`${ApiEndpoint.Dnd}/${id}/restore`),
    )
  }

  /// GAME
  async listGameItem(params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<GameItem>>(
      this._client.get(`${ApiEndpoint.Game}${paramStr}`),
    )
  }
  async getGameItem(id: string) {
    return this.send<GameItem>(this._client.get(`${ApiEndpoint.Game}/${id}`))
  }
  async addGameItem(body: GameItemReqBody) {
    return this.send<GameItem>(this._client.post(ApiEndpoint.Game, body))
  }
  async updateGameItem(id: string, body: GameItemReqBody) {
    return this.send<GameItem>(
      this._client.put(`${ApiEndpoint.Game}/${id}`, body),
    )
  }
  async deleteGameItem(id: string) {
    return this.send<void>(this._client.delete(`${ApiEndpoint.Game}/${id}`))
  }
  /// DOODLES
  async listDoodle(params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<DoodleItem>>(
      this._client.get(`${ApiEndpoint.Doodle}${paramStr}`),
    )
  }
  async listDoodleArchive(params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<DoodleItem>>(
      this._client.get(`${ApiEndpoint.Doodle}/archived${paramStr}`),
    )
  }
  async getDoodle(id: string) {
    return this.send<DoodleItem>(
      this._client.get(`${ApiEndpoint.Doodle}/${id}`),
    )
  }
  async addDoodle(body: DoodleItemReqBody) {
    return this.send<DoodleItem>(this._client.post(ApiEndpoint.Doodle, body))
  }
  async updateDoodle(body: DoodleItemReqBody) {
    return this.send<DoodleItem>(
      this._client.put(`${ApiEndpoint.Doodle}/${body.id}`, body),
    )
  }
  async deleteDoodle(id: string) {
    return this.send<void>(this._client.delete(`${ApiEndpoint.Doodle}/${id}`))
  }
  async restoreDoodle(id: string) {
    return this.send<void>(
      this._client.post(`${ApiEndpoint.Doodle}/${id}/restore`),
    )
  }

  /// PUZZLES
  async listPuzzle(params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<PuzzleItem>>(
      this._client.get(`${ApiEndpoint.Puzzle}${paramStr}`),
    )
  }
  async listPuzzleArchive(params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<PuzzleItem>>(
      this._client.get(`${ApiEndpoint.Puzzle}/archived${paramStr}`),
    )
  }
  async getPuzzle(id: string) {
    return this.send<PuzzleItem>(
      this._client.get(`${ApiEndpoint.Puzzle}/${id}`),
    )
  }
  async addPuzzle(body: PuzzleItemReqBody) {
    return this.send<PuzzleItem>(this._client.post(ApiEndpoint.Puzzle, body))
  }
  async updatePuzzle(body: PuzzleItemReqBody) {
    return this.send<PuzzleItem>(
      this._client.put(`${ApiEndpoint.Puzzle}/${body.id}`, body),
    )
  }
  async deletePuzzle(id: string) {
    return this.send<void>(this._client.delete(`${ApiEndpoint.Puzzle}/${id}`))
  }
  async restorePuzzle(id: string) {
    return this.send<void>(
      this._client.post(`${ApiEndpoint.Puzzle}/${id}/restore`),
    )
  }

  /// SEAT QUESTIONS
  async listSeatQuestionGroup(params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<SeatQuestionGroup>>(
      this._client.get(
        `${ApiEndpoint.Seat}/${ApiEndpoint.Question}/groups${paramStr}`,
      ),
    )
  }
  async getSeatQuestionGroup(id: string) {
    return this.send<SeatQuestionGroup>(
      this._client.get(
        `${ApiEndpoint.Seat}/${ApiEndpoint.Question}/groups/${id}`,
      ),
    )
  }
  async addSeatQuestionGroup(body: SeatQuestionGroupReqBody) {
    return this.send<SeatQuestionGroup>(
      this._client.post(
        `${ApiEndpoint.Seat}/${ApiEndpoint.Question}/groups`,
        body,
      ),
    )
  }
  async updateSeatQuestionGroup(body: SeatQuestionGroupReqBody) {
    return this.send<SeatQuestionGroup>(
      this._client.put(
        `${ApiEndpoint.Seat}/${ApiEndpoint.Question}/groups/${body.id}`,
        body,
      ),
    )
  }
  async deleteSeatQuestionGroup(id: string) {
    return this.send<void>(
      this._client.delete(
        `${ApiEndpoint.Seat}/${ApiEndpoint.Question}/groups/${id}`,
      ),
    )
  }

  async listSeatQuestion(params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    const res = await this.send<PaginatedItems<SeatQuestion>>(
      this._client.get(
        `${ApiEndpoint.Seat}/${ApiEndpoint.Question}${paramStr}`,
      ),
    )
    const data = {
      ...res,
      page: {
        totalPages: 1,
        perPage: res.data.length,
        total: res.data.length,
        current: 1,
      },
    }

    console.log('listSeatQuestion', data)
    return data
  }
  async getSeatQuestion(id: string) {
    return this.send<SeatQuestion>(
      this._client.get(`${ApiEndpoint.Seat}/${ApiEndpoint.Question}/${id}`),
    )
  }
  async addSeatQuestion(body: FormFieldReqBody) {
    return this.send<SeatQuestion>(
      this._client.post(`${ApiEndpoint.Seat}/${ApiEndpoint.Question}`, body),
    )
  }
  async updateSeatQuestion(body: FormFieldReqBody) {
    return this.send<SeatQuestion>(
      this._client.put(
        `${ApiEndpoint.Seat}/${ApiEndpoint.Question}/${body.id}`,
        body,
      ),
    )
  }
  async deleteSeatQuestion(id: string) {
    return this.send<void>(this._client.delete(`${ApiEndpoint.Question}/${id}`))
  }

  async listGame(params?: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    console.log(paramStr)
    return {
      data: DUMMY_GAMES,
      page: {
        totalPages: 1,
        perPage: 25,
        total: DUMMY_GAMES.length,
        current: 1,
      },
    } as PaginatedItems<Game>
    //return this.send<PaginatedItems<Game>>(
    //  this._client.get(`${ApiEndpoint.Game}${paramStr}`),
    //)
  }
  async getGame(id: string) {
    return this.send<Game>(this._client.get(`${ApiEndpoint.Game}/${id}`))
  }
  async addGame(body: GameReqBody) {
    return this.send<Game>(this._client.post(ApiEndpoint.Game, body))
  }
  async updateGame(id: string, body: Game) {
    return this.send<Game>(this._client.put(`${ApiEndpoint.Game}/${id}`, body))
  }
  async deleteGame(id: string) {
    return this.send<void>(this._client.delete(`${ApiEndpoint.Game}/${id}`))
  }

  async uploadImportSubject(file: File, config?: AxiosRequestConfig) {
    return this.uploadImport('subject', file, config)
  }

  async uploadImportLesson(file: File, config?: AxiosRequestConfig) {
    return this.uploadImport('lesson', file, config)
  }

  async uploadImportMCQ(file: File, config?: AxiosRequestConfig) {
    return this.uploadImport('question', file, config)
  }

  async uploadImport(
    ep: 'subject' | 'lesson' | 'question',
    file: File,
    config?: AxiosRequestConfig,
  ) {
    const formData = new FormData()
    formData.set('files', file)

    const cfg = config || {}

    cfg.headers = {
      ...cfg.headers,
      'Content-Type': 'multipart/form-data',
    }

    return this.send(this._client.post(`import/${ep}`, formData, cfg))
  }
  async importLegacySubject(body: number[]) {
    return this.send<PaginatedItems<LegacySubject>>(
      this._client.post(`legacy/subjects`, {
        legacyId: body,
      }),
    )
  }
  async listLegacySubject(params?: Params) {
    return this.sendParams<PaginatedItems<LegacySubject>>(
      'legacy/subjects',
      params,
    )
  }
  async updateTutorial(next: TutorialRaw) {
    return this.send<TutorialRaw>(this._client.post(`tutorials`, next))
  }
  async getTutorial(tId: string) {
    return this.send<TutorialRaw>(this._client.get(`tutorials/${tId}`))
  }
  async listTutorial(params?: Params) {
    return this.sendParams<PaginatedItems<TutorialRaw>>('tutorials', params)
  }

  async updateTour(next: Tour) {
    return this.send<Tour>(this._client.post(`tours`, next))
  }
  async getTour(tId: string) {
    return this.send<Tour>(this._client.get(`tours/${tId}`))
  }
  async listTour(params?: Params) {
    return this.sendParams<PaginatedItems<Tour>>('tours', params)
  }
  async updateUserPreferences(value: Record<string, unknown>) {
    return this.send(this._client.put(`users/preferences`, value))
  }
}
export function getApiService(): ApiService {
  return ApiService.getInstance()
}
