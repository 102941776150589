import './VideoStream.css'

import { Sym } from '@edclass/fe-ui'
import { StageStream } from 'amazon-ivs-web-broadcast'
import clsx from 'clsx'
import { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react'

import { EDIcon } from '@/constants/constants.ts'
import { useAudioLevel } from '@/hooks/useAudioLevel.ts'

function StreamImpl({
  //stageStreams,
  autoPlay = true,
  className,
  wrapperClassName,
  controls,
  id,
  stream,
  forceUnmute,
  allowMute,
  style,
  trackAudio,
  allowFs,
}: {
  forceUnmute?: boolean
  id?: string
  autoPlay?: boolean
  stream?: MediaStream
  wrapperClassName?: string
  className?: string
  controls?: boolean
  allowMute?: boolean
  style?: CSSProperties
  trackAudio?: boolean
  allowFs?: boolean
}) {
  // required to be muted by default or there will be browser error policy if autoplay is on
  const [muted, setMuted] = useState(!forceUnmute)
  const videoRef = useRef<HTMLMediaElement | null>(null)

  useEffect(() => {
    if (videoRef.current && stream && autoPlay) {
      // Set the srcObject and make sure autoplay works
      videoRef.current.srcObject = stream
      videoRef.current.play().catch((err) => {
        console.error('Autoplay failed:', err)
      })
    }
  }, [stream, autoPlay]) // This will re-run when mediaStream changes

  const audioLevel = useAudioLevel(trackAudio ? stream : undefined)

  return (
    <div
      className={clsx(
        'video-stream-wrapper',
        wrapperClassName,
        audioLevel > 10 ? 'speaking' : '',
      )}
    >
      {allowMute && (
        <button
          onClick={() => setMuted(!muted)}
          className="absolute z-[10] bottom-1 right-1 rounded bg-black/30 p-1"
        >
          <Sym className="!text-[16px]">
            {muted ? 'volume_off' : 'volume_up'}
          </Sym>
        </button>
      )}
      {allowFs && (
        <button
          onClick={() =>
            videoRef.current?.requestFullscreen({
              navigationUI: 'hide',
            })
          }
          className="absolute z-[10] bottom-1 right-1 rounded bg-black/30 p-1"
        >
          <Sym className="!text-[16px]">fullscreen</Sym>
        </button>
      )}
      <video
        ref={videoRef as never}
        id={id}
        muted={muted}
        className={className}
        autoPlay={autoPlay}
        controls={controls}
        style={style}
      />
    </div>
  )
}
export default function VideoStream({
  stageStreams,
  size = 'xs',
  className,
  placeholder,
  placeholderIcon,
  stream,
  ...props
}: {
  id?: string
  autoPlay?: boolean
  stageStreams?: StageStream[]
  stream?: MediaStream
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'auto'
  className?: string
  wrapperClassName?: string
  placeholder?: ReactNode
  placeholderIcon?: string
  controls?: boolean
  forceUnmute?: boolean
  allowMute?: boolean
  style?: CSSProperties
  trackAudio?: boolean
}) {
  const mediaClassName = clsx('video-stream', size, className)

  if (!stageStreams && !stream) {
    return placeholder ? (
      placeholder
    ) : (
      <div className={clsx(mediaClassName, 'cc placeholder')}>
        <Sym className="!text-[40px]">{placeholderIcon || EDIcon.EDLive}</Sym>
      </div>
    )
  }

  return <StreamImpl {...props} stream={stream} className={mediaClassName} />
}
